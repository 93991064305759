<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg">TESTAR</p>
        <div class="container">
            <h2 class="page_header_title">TESTAR</h2>
        </div>
    </div>
</div>
<div class="map">
    <div class="container">
        <div class="row no-gutters align-items-center mb-5">


            <div class="col-sm-6">
                <img src="assets/images/test1.png" alt="">
            </div>
            <div class="col-sm-6">
                <p class="page_subtitle mb-4 text-white"><strong>TESTAR</strong></p>

                <p class="page_text text-white">
                    Na sexta-feira do Design Sprint, vocês mostrarão o protótipo para cinco clientes em cinco entrevistas individuais. Em vez de esperarem por um lançamento para obterem insights, vocês obterão as respostas que precisam imediatamente.
                </p>
                <img src="assets/images/testline.png" alt="">
            </div>
        </div>

    </div>
</div>


<div class="container">
    <div class="row no-gutters align-items-start mb-5">


        <div class="col-sm-3">
            <p class="page_subtitle mb-4 ">
                <strong>Informações</strong>
            </p>
            <br>
            <span>
                <strong>Fase:</strong>
                Prototipar
            </span>
            <br>
            <span>
               <strong>Dia:</strong>
             Sexta-feira
            </span>
            <br>
            <span>
               <strong>Livro:</strong>
              Página 196
            </span>

        </div>
        <div class="col-sm-9">


            <p>
                A semana do <strong [routerLink]="'/explicar'">Design Sprint</strong> começou com um <strong [routerLink]="'/desafio'">grande desafio</strong> , uma <strong [routerLink]="'/desafio'">excelente equipe</strong>  — e não muito mais do que isso. Na sexta-feira vocês já terão criado <strong [routerLink]="'/esbocarsolucoes'">soluções promissoras</strong> , <strong [routerLink]="'/supervoto'">escolhido as melhores</strong> e construído um <strong>protótipo</strong>  realista. Só isso já daria uma semana incrivelmente produtiva. Mas vocês ainda darão mais um passo quando <strong [routerLink]="'/entrevistar'">entrevistarem</strong> clientes e aprenderem observando suas reações ao <strong [routerLink]="'/prototipo'">protótipo</strong>. Esse teste é o que faz todo o <strong [routerLink]="'/desafio'">Design Sprint</strong> valer a pena: no fim do dia, vocês saberão para onde devem ir e o que exatamente devem fazer em seguida.
            </p>

            <br>
            <img src="assets/images/test2.png" alt="">
            <br>
            <br>
            <p>
                Claro, que o <strong [routerLink]="'/livro'">Livro Sprint</strong>  do <strong [routerLink]="'/jakeknapp'">Jake Knapp</strong>  e o nosso  <strong [routerLink]="'/curso'">Curso Online</strong> são guias muito mais completos para as atividades da etapa Decidir e o resto da semana do <strong [routerLink]="'/explicar'">Design Sprint.</strong> Mas, incluímos um checklist completo da etapa Decidir e algumas dicas para vocês logo abaixo. Também temos um vídeo onde explicamos o que acontece na etapa Decidir e mostramos alguns exemplos práticos.
                <br>
                <br>
                Se mesmo assim vocês ainda tiverem dúvidas, confiram a página de <strong [routerLink]="'/faq'">Perguntas Frequentes</strong>. E, se não encontrarem as respostas que precisam, sintam-se à vontade para nos enviar uma mensagem através da nossa página de <strong [routerLink]="'/contato'">contato</strong>. Será sempre um prazer poder ajudá-los.
                <br>
                <br>
                E, finalmente, aqui está o checklist da etapa Decidir que prometemos. Vem com a gente!
            </p>

            <br>
            <br>
            <p class="page_subtitle mb-4 "><strong>CHECKLIST</strong></p>
            <span><strong>Lembrete 1:</strong> Os horários são aproximados. Não se preocupem se vocês precisarem adiantar um pouco, atrasar um pouco ou se precisarem fazer alguns ajustes ao longo das atividades.</span>
            <br>
            <span><strong>Lembrete 2:</strong> Façam um intervalo para o almoço das 13h00 às 14h00 todos os dias. Comam juntos se for possível (é divertido). Escolham pratos leves para conservar a energia à tarde. Haverá lanchinhos se ficarem com fome depois.</span>
            <br>
            <span><strong>Lembrete 3:</strong> Façam pausas a cada sessenta ou a cada noventa minutos. Por volta das 11h00 e 15h45 todos os dias.</span>
            <br>
            <br>
            <div class="faq-group">

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-1')" href="javascript:void(0)">
                        <span>Como entrevistar</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-1" class="collapse">
                        <p>⌼ -     ⌻ -     ⌺ Stakeholders     ⌹ 233</p>

                        <span>Parece pouco, mas após cinco entrevistas, surgirão padrões importantes. Façam todas as cinco entrevistas no mesmo dia.</span>
                        <br>

                        <a [routerLink]="'/entrevistar'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-2')" href="javascript:void(0)">
                        <span>Preparar as salas</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-2" class="collapse">
                        <p>⌼ 9:00h     ⌻ 60min     ⌺ Todos     ⌹ 238</p>

                        <span>Na sala do Design Sprint, a equipe assistirá à transmissão das entrevistas. Vocês vão precisar de uma segunda sala menor para as entrevistas. Certifiquem-se de que a sala de entrevista esteja limpa e seja confortável para os convidados.</span>
                        <br>

                        <a [routerLink]="'/preparar'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-3')" href="javascript:void(0)">
                        <span>Painel de dados</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-3" class="collapse">
                        <p>⌼ 10:00h     ⌻ 15min     ⌺ Parte da equipe     ⌹ 258</p>

                        <span>Façam uma tabela em um quadro branco. Reservem uma coluna para cada cliente. Em seguida, adicionem uma linha para cada protótipo ou cada seção do protótipo.</span>
                        <br>

                        <a [routerLink]="'/paineldados'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-4')" href="javascript:void(0)">
                        <span>Fazer as entrevistas</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-4" class="collapse">
                        <p>⌼ 10:15h     ⌻ 225min     ⌺ Entrevistador     ⌹ 238</p>

                        <span>Essas conversas estruturadas ajudam o cliente a ficar à vontade, estabelecem um contexto e garantem que todo o protótipo seja analisado.</span>
                        <br>

                        <a [routerLink]="'/entrevistar'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-5')" href="javascript:void(0)">
                        <span>Assistir juntos</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-5" class="collapse">
                        <p>⌼ 10:15h     ⌻ 225min     ⌺ Parte da equipe     ⌹ 257</p>

                        <span>Não desmantelem a equipe do Design Sprint. Assistir à entrevista juntos é mais eficiente, e vocês vão tirar conclusões mais precisas.</span>
                        <br>

                        <a [routerLink]="'/assistir'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-6')" href="javascript:void(0)">
                        <span>Anotar enquanto assiste</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-6" class="collapse">
                        <p>⌼ 10:15h     ⌻ 225min     ⌺ Parte da equipe     ⌹ 258</p>

                        <span>Distribuam notas autoadesivas e pincéis atômicos. Registrem citações diretas, observações e interpretações. Indiquem se foram positivas ou negativas.</span>
                        <br>

                        <a [routerLink]="'/anotar'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-7')" href="javascript:void(0)">
                        <span>Inserir as notas</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-7" class="collapse">
                        <p>⌼ 10:15h     ⌻ 225min     ⌺ Parte da equipe     ⌹ 259</p>

                        <span>Colem as anotações nas linhas e colunas corretas da tabela no quadro branco. Discutam rapidamente a entrevista, mas evitem tirar conclusões nesse momento.</span>
                        <br>

                        <a [routerLink]="'/inserir'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-8')" href="javascript:void(0)">
                        <span>Identificar padrões</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-8" class="collapse">
                        <p>⌼ 16:00h     ⌻ 30min     ⌺ Todos     ⌹ 261</p>

                        <span>No fim do dia, leiam o quadro em silêncio e anotem os padrões que surgirem. Façam uma lista de todos os padrões identificaram. Rotulem cada um como positivo, negativo ou neutro.</span>
                        <br>

                        <a [routerLink]="'/identificar'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-9')" href="javascript:void(0)">
                        <span>Encerrar a semana</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-9" class="collapse">
                        <p>⌼ 16:30h     ⌻ 30min     ⌺ Todos     ⌹ 261</p>

                        <span>Revisem o objetivo de longo prazo e as perguntas do Design Sprint. Comparem com os padrões identificados nas entrevistas. Para finalizar, decidam quais serão os próximos passos.</span>
                        <br>

                        <a [routerLink]="'/encerrar'">Saiba Mais --></a>
                    </div>
                </div>





            </div>
            <br>


            <p>
                E aí, o que achou sobre este conteúdo? Esperamos que esteja gostando, mas principalmente que esteja
                sendo útil e muito relevante para você!
                <br>
                <br>
                Nós aqui da <strong>DesignSprint.com.br</strong> já vivemos na pele o que você deve estar vivendo agora…
                Sabemos que o mercado de Design, Metodologias Ágeis e Experiência do Usuário (UX) cresce cada vez mais,
                mas também muda muito a cada minuto, a cada segundo – e os profissionais mais atualizados sempre saem na
                frente na hora de serem contratados.
                <br>
                <br>
                Mas se disséssemos que você pode chegar preparadíssimo para uma entrevista nas maiores agências de
                Design do Brasil? Imagina só: trabalhar diretamente na área que você almeja e ainda ser referência para
                os seus colegas!
                <br>
                <br>
                Sim, você pode aprender muita coisa através do conteúdo gratuito que disponibilizamos aqui no site, mas
                você precisa descobrir e ter acesso a todos os segredos, atalhos, ferramentas, materiais exclusivos e
                conteúdos em vídeo que farão de você um profissional de ponta nesse mercado.
                <br>
                <br>
                <strong>PARA ISSO, É SÓ SE INSCREVER NA PRÓXIMA TURMA DO NOSSO CURSO ONLINE DE DESIGN SPRINT. FICOU
                    INTERESSADO(A)?</strong>
                <a class="text-primary" href="javascript:void(0)" [routerLink]="'/curso'">ENTÃO CLIQUE AQUI E GARANTA JÁ
                    A SUA VAGA ANTES QUE ACABE!</a><strong class="text-primary"></strong>

            </p>

            <div class="bottom-selector">

                <div class="bottom-selector-btn" [routerLink]="'/confirmar'">
                    Anterior
                </div>
                <div class="bottom-selector-btn" [routerLink]="'/entrevistar'">
                    Próximo
                </div>
            </div>
        </div>
    </div>

</div>

