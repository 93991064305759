import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-icebreaker',
  templateUrl: './icebreaker.component.html',
  styleUrls: ['./icebreaker.component.scss']
})
export class IcebreakerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
