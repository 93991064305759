import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from './views/main/main.component';
import {HeaderComponent} from './views/header/header.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt';
import {AppDataService} from './service/app_data.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxPaginationModule} from 'ngx-pagination';
import {HomeComponent} from './views/home/home.component';
import {AutocompleteComponent} from './util/autocomplete/autocomplete.component';
import {UserComponent} from './views/user/user.component';
import {HeaderHttpInterceptor} from './base/header-interceptor';
import {LoginComponent} from './views/login/login.component';
import {GraphQLModule} from './graphql.module';
import {CURRENCY_MASK_CONFIG} from 'ng2-currency-mask';
import {NgxMaskModule} from "ngx-mask";
import {ProfileComponent} from './views/profile/profile.component';
import {FooterComponent} from './views/footer/footer.component';
import {PrizeDetailComponent} from './views/prize-detail/prize-detail.component';
import {PrizesComponent} from './views/prizes/prizes.component';
import {CampaignComponent} from './views/campaign/campaign.component';
import {NewsComponent} from './views/news/news.component';
import {RecoveryComponent} from './views/recovery/recovery.component';
import {FaqComponent} from './views/faq/faq.component';
import {ContactComponent} from './views/contact/contact.component';
import {CropImageComponent} from './views/crop-image/crop-image.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {DownloadsComponent} from './views/downloads/downloads.component';
import {LogoutComponent} from "./views/logout/logout.component";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {NewsDetailComponent} from "./views/news-detail/news-detail.component";
import {PrizesEqComponent} from './views/prizes/prizes-eq/prizes-eq.component';
import {PrizesRvComponent} from './views/prizes/prizes-rv/prizes-rv.component';
import {PaginationCustomModule} from "./views/pagination-custom/pagination-custom.module";
import { PrivacyPolicyComponent } from './views/privacy-policy/privacy-policy.component';
import { LgpdComponent } from './views/lgpd/lgpd.component';
import { DoubtsComponent } from './views/doubts/doubts.component';
import {WinnersComponent} from './views/winners/winners.component';
import {LottieModule} from 'ngx-lottie';
import player from 'lottie-web';
import { AboutComponent } from './views/about/about.component';
import { StoryComponent } from './views/story/story.component';
import { JakeknappComponent } from './views/jakeknapp/jakeknapp.component';
import { BookComponent } from './views/book/book.component';
import { OrganizeComponent } from './views/organize/organize.component';
import { MapComponent } from './views/map/map.component';
import { SketchComponent } from './views/sketch/sketch.component';
import { DecideComponent } from './views/decide/decide.component';
import { PrototypeComponent } from './views/prototype/prototype.component';
import { TestComponent } from './views/test/test.component';
import { CasesComponent } from './views/cases/news.component';
import { CasesDetailComponent } from './views/cases-detail/news-detail.component';
import { TrademarkComponent } from './views/trademark/trademark.component';
import { CourseComponent } from './views/course/course.component';
import { ScheduleComponent } from './views/schedule/schedule.component';
import { ExplainComponent } from './views/explain/explain.component';
import { StoryboardComponent } from './views/storyboard/storyboard.component';
import { TeamComponent } from './views/team/team.component';
import {ArticlesComponent} from "./views/articles/news.component";
import {ArticlesDetailComponent} from "./views/articles-detail/news-detail.component";
import { ChallengeComponent } from './views/challenge/challenge.component';
import { DefinerComponent } from './views/definer/definer.component';
import { SpecialistComponent } from './views/specialist/specialist.component';
import { FacilitatorComponent } from './views/facilitator/facilitator.component';
import { RoomComponent } from './views/room/room.component';
import { MaterialsComponent } from './views/materials/materials.component';
import { TargetComponent } from './views/target/target.component';
import { ObjectiveComponent } from './views/objective/objective.component';
import { QuestionsComponent } from './views/questions/questions.component';
import { MapedComponent } from './views/maped/maped.component';
import { IcebreakerComponent } from './views/icebreaker/icebreaker.component';
import { CpComponent } from './views/cp/cp.component';
import { OrganizeCpComponent } from './views/organize-cp/organize-cp.component';
import { VoteCpComponent } from './views/vote-cp/vote-cp.component';
import { SearchIdeasComponent } from './views/search-ideas/search-ideas.component';
import { SketchSolutionsComponent } from './views/sketch-solutions/sketch-solutions.component';
import { InterviewComponent } from './views/interview/interview.component';
import { RecruitComponent } from './views/recruit/recruit.component';
import { PresentIdeasComponent } from './views/present-ideas/present-ideas.component';
import { DivideComponent } from './views/divide/divide.component';
import { AnotateComponent } from './views/anotate/anotate.component';
import { Crazy8Component } from './views/crazy8/crazy8.component';
import { DefineGoalComponent } from './views/define-goal/define-goal.component';
import { MuseumComponent } from './views/museum/museum.component';
import { HeatComponent } from './views/heat/heat.component';
import { CritiqueComponent } from './views/critique/critique.component';
import { IntentionComponent } from './views/intention/intention.component';
import { SupervoteComponent } from './views/supervote/supervote.component';
import { BattleComponent } from './views/battle/battle.component';
import { InventComponent } from './views/invent/invent.component';
import { OpenComponent } from './views/open/open.component';
import { HowPrototypeComponent } from './views/how-prototype/how-prototype.component';
import { ToolComponent } from './views/tool/tool.component';
import { ConquestComponent } from './views/conquest/conquest.component';
import { StitchComponent } from './views/stitch/stitch.component';
import { ReviewComponent } from './views/review/review.component';
import { RoadmapComponent } from './views/roadmap/roadmap.component';
import { ConfirmComponent } from './views/confirm/confirm.component';
import { PrepareComponent } from './views/prepare/prepare.component';
import { DataPanelComponent } from './views/data-panel/data-panel.component';
import { WatchComponent } from './views/watch/watch.component';
import { InsertComponent } from './views/insert/insert.component';
import { IdentifyComponent } from './views/identify/identify.component';
import { FinishComponent } from './views/finish/finish.component';
import {ContentDetailComponent} from "./views/content-detail/news-detail.component";
import { PaymentComponent } from './views/payment/payment.component';
import { NotMemberComponent } from './views/not-member/not-member.component';

registerLocaleData(localePt, 'pt-BR');

export function playerFactory() {
    return player;
}


export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        HeaderComponent,
        HomeComponent,
        AutocompleteComponent,
        UserComponent,
        LoginComponent,
        ProfileComponent,
        FooterComponent,
        PrizeDetailComponent,
        PrizesComponent,
        CampaignComponent,
        NewsComponent,
        RecoveryComponent,
        FaqComponent,
        ContactComponent,
        DownloadsComponent,
        CropImageComponent,
        LogoutComponent,
        NewsDetailComponent,
        PrizesEqComponent,
        PrizesRvComponent,
        PrivacyPolicyComponent,
        LgpdComponent,
        DoubtsComponent,
        WinnersComponent,
        AboutComponent,
        StoryComponent,
        JakeknappComponent,
        BookComponent,
        OrganizeComponent,
        MapComponent,
        SketchComponent,
        DecideComponent,
        PrototypeComponent,
        TestComponent,
        CasesComponent,
        CasesDetailComponent,
        TrademarkComponent,
        CourseComponent,
        ScheduleComponent,
        ExplainComponent,
        StoryboardComponent,
        TeamComponent,
        ScheduleComponent,
        ArticlesComponent,
        ArticlesDetailComponent,
        ChallengeComponent,
        DefinerComponent,
        SpecialistComponent,
        FacilitatorComponent,
        RoomComponent,
        MaterialsComponent,
        TargetComponent,
        ObjectiveComponent,
        QuestionsComponent,
        MapedComponent,
        IcebreakerComponent,
        CpComponent,
        OrganizeCpComponent,
        VoteCpComponent,
        SearchIdeasComponent,
        SketchSolutionsComponent,
        InterviewComponent,
        RecruitComponent,
        PresentIdeasComponent,
        DivideComponent,
        AnotateComponent,
        Crazy8Component,
        DefineGoalComponent,
        MuseumComponent,
        HeatComponent,
        CritiqueComponent,
        IntentionComponent,
        SupervoteComponent,
        BattleComponent,
        InventComponent,
        OpenComponent,
        HowPrototypeComponent,
        ToolComponent,
        ConquestComponent,
        StitchComponent,
        ReviewComponent,
        RoadmapComponent,
        ConfirmComponent,
        PrepareComponent,
        DataPanelComponent,
        WatchComponent,
        InsertComponent,
        IdentifyComponent,
        FinishComponent,
        ContentDetailComponent,
        PaymentComponent,
        NotMemberComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        HttpClientModule,
        AngularSvgIconModule.forRoot(),
        SlickCarouselModule,
        FormsModule,
        GraphQLModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgxPaginationModule,
        NgxMaskModule.forRoot(),
        ImageCropperModule,
        InfiniteScrollModule,
        PaginationCustomModule,
        HammerModule,
        [LottieModule.forRoot({player: playerFactory})]
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderHttpInterceptor,
            multi: true
        },
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR'
        },
        {
            provide: DEFAULT_CURRENCY_CODE,
            useValue: 'BRL'
        },
        {
            provide: CURRENCY_MASK_CONFIG,
            useValue: {   // BRL
                align: 'left',
                allowNegative: true,
                decimal: ',',
                precision: 2,
                prefix: 'R$ ',
                suffix: '',
                thousands: '.'
            }
        },
        TranslateService,
        AppDataService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
