import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prepare',
  templateUrl: './prepare.component.html',
  styleUrls: ['./prepare.component.scss']
})
export class PrepareComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
