import {Component, OnInit} from '@angular/core';
import {
    ClickDownloadGQL, CreateDownloadAccessInput, CreateOrderGQL, CreateOrderInput,
    Download,
    DownloadByCategoryQuery,
    DownloadPage,
    SearchDownloadGQL,
    SearchDownloadInput, UserOrder
} from '../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {environment} from '../../../environments/environment';
import {BaseComponent} from '../../base/base.component';
import {getToken} from 'codelyzer/angular/styles/cssLexer';

@Component({
    selector: 'app-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.scss']
})
export class PaymentComponent extends BaseComponent implements OnInit {
    labels: any = {
        previousLabel: '',
        nextLabel: '',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };
    section: string;
    downloads: Download[] = [];
    imageUrl: string;

    flagMember = false;
    checkout_url = environment.checkout_url;

    constructor(public router: Router,
                public createOrderGQL: CreateOrderGQL,
                public translate: TranslateService,
                public userService: UserService) {
        super(router, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.imageUrl = environment.image_url;
                if (!this.isNullOrUndefined(data.id)) {
                    this.flagMember = this.userService.getStatusAuthenticated();
                }
            }, error: err => super.onError(err)
        });


    }

    createOrder() {
        const input: CreateOrderInput = {
            type: 'member',
            value: 20.0
        };
        this.createOrderGQL.mutate({input}).subscribe(({data}) => {
            const order = data.createOrder as UserOrder;
            window.open(this.checkout_url + '?d=order&id=' + order.id + '&t=' + localStorage.getItem('token'),
                '_blank');
        }, error => super.onError(error));
    }

}
