<div class="container-xxl p-0  container-hero">
    <div class="carousel slick-home" id="slick-banner">
        <div *ngIf="banner.length === 0">
            <img class="slick-home-thumb"
                 src="/assets/images/banner/time-preparado.png" alt="banner">
        </div>
        <div *ngFor="let b of banner; let i = index;">
            <img (click)="clickBanner(b)" class="slick-home-thumb"
                 src="{{this.getImageURL() + 'banner/image/' + b.pathImage}}" alt="banner">
        </div>
    </div>
</div>

<div class="container">
    <div class="row my-3 my-sm-5 mx-n4" style="row-gap: 25px">
        <div class="col-lg-5 px-4">
            <h1 class="home_title">Agenda</h1>
            <div class="row no-gutters justify-content-between" style="row-gap: 15px">
                <div class="col-sm-3 position-relative">
                    <div class="row h-100 mx-n1 align-content-center" style="row-gap: 30px">
                        <div class="col px-1 col-sm-12" *ngFor="let item of listEngagedReseller;let i = index" (click)="item.active ? selectMouth = i : false">
                            <!-- (click)="selectMouth = i"-->
                            <div class="home-training_btn" [ngClass]="{'active':i == selectMouth}">
                                <p class="home-training_lbl">{{item.name}}</p>
                                <p class="home-training_lbl home-training_lbl-sm">{{item.activeAt}}</p>
                            </div>
                            <svg-icon class="home-training_arrow" src="assets/svg/arrow-training.svg"></svg-icon>
                            <!-- [ngClass]="{'active':i == selectMouth}"-->
                        </div>
                    </div>
                    <div class="home-training_bar">&nbsp;</div>
                </div>
                <div class="col-sm-8 pl-sm-3">
                    <div class="carousel" id="slick-quiz">
                        <ng-container *ngFor="let item of listEngagedReseller;let i = index">
                            <div [ngClass]="{'d-none' : i !== selectMouth}">
                                <h4 class="home-training_title"><small style="font-size: 11px">{{item.activeAt}}</small> | {{item.name}}</h4>
                                <p class="home_text font-weight-bold mb-0">Case 2023</p>
                                <p class="home_text">Acompanhe nossa agenda e participe dos eventos!</p>
                                <img style="width: 100%;" src="assets/images/home/video-{{item.name.toLowerCase()}}.png" alt="video" class="mb-2">
                                <a href="javascript:void('');" class="btn btn-block btn-outline-secondary">
                                    + Detalhes</a>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-1 px-4">
            <div class="row">
                <div class="col">
                    <h1 class="home_title"></h1>
                </div>
                <div class="col-12">
                    <div class="row align-items-center mx-n1 d-none">
                        <div class="col-12 px-1 pb-2">
                            <!--<p class="home-news_lbl mb-0">Veja todas as notícias</p>-->
                            <iframe width="100%" height="160" src="https://www.youtube.com/embed/S-3S2pvowGg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div class="col-12 px-1">
                            <!--<p class="home-news_title">O  Design Sprint começou!</p>
                            <p class="home-news_text">Assista ao vídeo para conferir as novidades, as mecânicas e os prêmios para iniciar a campanha a todo gás.</p>-->
                            <!--<div class="row justify-content-between align-items-center">
                                <div class="col-auto">
                                    <p class="home-news_date mb-0">Loren ipsum dolor sit amet</p>
                                </div>
                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 px-4">
            <div class="row">
                <div class="col">
                    <h1 class="home_title">Notícias</h1>
                </div>
                <div class="col-auto">
                    <a class="row align-items-center mx-n1" routerLink="/noticias">
                        <div class="col px-1">
                            <p class="home-news_lbl mb-0">Veja todas as notícias</p>
                        </div>
                        <div class="col-auto px-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="6.421" height="10.918"
                                 viewBox="0 0 6.421 10.918">
                                <g id="Icon_ionic-ios-arrow-dropleft" data-name="Icon ionic-ios-arrow-dropleft"
                                   transform="translate(-7.52 -4.501)">
                                    <path id="Caminho_56" data-name="Caminho 56"
                                          d="M12.436,10.252a.928.928,0,0,1,1.308,0l4.57,4.584a.923.923,0,0,1,.029,1.274l-4.5,4.517a.923.923,0,1,1-1.308-1.3l3.822-3.88L12.431,11.56A.924.924,0,0,1,12.436,10.252Z"
                                          transform="translate(-4.642 -5.481)" fill="#032246"/>
                                </g>
                            </svg>
                        </div>
                    </a>
                </div>
            </div>
            <div class="carousel" id="slick-news">
                <div *ngFor="let n of this.newsPage.news">
                    <div class="home-news_item">
                        <div class="home-news_thumb">
                            <img src="{{this.getImageURL() + 'news/image/' +n.pathImage}}" alt="image">
                        </div>
                        <div class="px-3">
                            <p class="home-news_title">{{n.title}}</p>
                            <p class="home-news_text" [innerHTML]="n.text"></p>
                            <div class="row justify-content-between align-items-center">
                                <div class="col-auto">
                                    <p class="home-news_date mb-0">{{n.createdAt | date:'dd/MM/yyyy | HH:mm':'UTC-0'}}</p>
                                </div>
                                <div class="col-auto">
                                    <a class="home-news_link cursor-pointer" (click)="clickNewsAccess(n.id, '/noticias-detalhe/')">Leia
                                        mais</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="w-100 my-5">
        <hr />
    </div>
    <div class="row">
        <div class="col-12 p-4 my-5">
            <div class="row">
                <div class="col">
                    <h1 class="home_title">Artigos</h1>
                </div>
                <div class="col-auto">
                    <a class="row align-items-center mx-n1" routerLink="/artigos">
                        <div class="col px-1">
                            <p class="home-news_lbl mb-0">Veja todos os artigos</p>
                        </div>
                        <div class="col-auto px-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="6.421" height="10.918"
                                 viewBox="0 0 6.421 10.918">
                                <g id="Icon_ionic-ios-arrow-dropleft" data-name="Icon ionic-ios-arrow-dropleft"
                                   transform="translate(-7.52 -4.501)">
                                    <path id="Caminho_56" data-name="Caminho 56"
                                          d="M12.436,10.252a.928.928,0,0,1,1.308,0l4.57,4.584a.923.923,0,0,1,.029,1.274l-4.5,4.517a.923.923,0,1,1-1.308-1.3l3.822-3.88L12.431,11.56A.924.924,0,0,1,12.436,10.252Z"
                                          transform="translate(-4.642 -5.481)" fill="#032246"/>
                                </g>
                            </svg>
                        </div>
                    </a>
                </div>
            </div>
            <div class="carousel" id="slick-articles">
                <div *ngFor="let n of this.articlesPage.news">
                    <div class="home-news_item">
                        <div class="home-news_thumb">
                            <img src="{{this.getImageURL() + 'news/image/' +n.pathImage}}" alt="image">
                        </div>
                        <div class="px-3">
                            <p class="home-news_title">{{n.title}}</p>
                            <p class="home-news_text" [innerHTML]="n.text"></p>
                            <div class="row justify-content-between align-items-center">
                                <div class="col-auto">
                                    <p class="home-news_date mb-0">{{n.createdAt | date:'dd/MM/yyyy | HH:mm':'UTC-0'}}</p>
                                </div>
                                <div class="col-auto">
                                    <a class="home-news_link cursor-pointer" (click)="clickNewsAccess(n.id, '/artigos-detalhe/')">Leia
                                        mais</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="bg-primary">
    <div class="container-sm py-5">
        <h1 class="home_title text-white">DESIGN SPRINT</h1>

        <div class="row">
            <div class="col-12 col-md-3 mb-3 home-card">
                <h3 class="home_title text-white"><span>SOBRE</span></h3>
                <img src="assets/images/about1.png" class="img-thumb" alt="">
                <p class="home_text text-white">
                    Quer saber mais sobre o método usado no Google para testar e aplicar novas ideias em apenas cinco dias?
                </p>
                <div class="row home_grid">
                    <div class="col-12">
                        <div class="home_card custom-height text-tertiary" [routerLink]="'/sobre'">
                            <p class="home_card_lbl mb-0"><strong>VEJA MAIS +</strong></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-3 mb-3 home-card">
                <h3 class="home_title text-white"><span>HISTÓRIA</span></h3>
                <img src="assets/images/story.png" class="img-thumb" alt="">
                <p class="home_text text-white">
                    O designer Jake Knapp do Google estava buscando uma alternativa para os brainstormings em grupo que não estavam funcionando bem. Por isso, em 2010 ele criou um método para as equipes progredirem do desafio à solução prototipada e testada em apenas cinco dias.
                </p>
                <div class="row home_grid">
                    <div class="col-12">
                        <div class="home_card custom-height text-tertiary" [routerLink]="'/historia'">
                            <p class="home_card_lbl mb-0"><strong>VEJA MAIS +</strong></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-3 mb-3 home-card">
                <h3 class="home_title text-white"><span>JAKE KNAPP</span></h3>
                <img src="assets/images/jakeknapp1.png" class="img-thumb" alt="">
                <p class="home_text text-white">
                    Jake Knapp passou 10 anos no Google e no Google Ventures, onde criou o Design Sprint. Ele escreveu dois livros, Sprint e Make Time , treinou equipes em lugares como Slack, LEGO, IDEO e NASA, e foi instrutor convidado no MIT e na Harvard Business School.
                </p>
                <div class="row home_grid">
                    <div class="col-12">
                        <div class="home_card custom-height text-tertiary" [routerLink]="'/jakeknapp'">
                            <p class="home_card_lbl mb-0"><strong>VEJA MAIS +</strong></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-3 mb-3 home-card">
                <h3 class="home_title text-white"><span>LIVRO</span></h3>
                <img src="assets/images/book1.png" class="img-thumb" alt="">
                <p class="home_text text-white">
                    Leiam o livro e façam tudo o que ele diz se quiserem criar produtos ou serviços melhores e mais rapidamente.
                </p>
                <div class="row home_grid">
                    <div class="col-12">
                        <div class="home_card custom-height text-tertiary" [routerLink]="'/livro'">
                            <p class="home_card_lbl mb-0"><strong>VEJA MAIS +</strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-sm py-5">
    <h1 class="home_title">ETAPAS</h1>
    <div class="row" style="row-gap:20px">
        <div class="col-12">
            <!--<h2 class="standardization_title mb-3">Cupons</h2>
            <p class="home_text"> O sorteio dos cupons será do dia 28/02/2023 </p>-->
            <div class="row" style="row-gap: 20px">
                <div class="col-sm-4" [routerLink]="'/organizar'">
                    <div class="coupon_card">
                        <img class="coupon_img"
                             src="assets/images/organize1.png" alt="logo-dark-small"/>
                        <p class="coupon_lbl" >Organizar</p>
                    </div>
                </div>
                <div class="col-sm-4" [routerLink]="'/mapear'">
                    <div class="coupon_card">
                        <img class="coupon_img"
                             src="assets/images/map1.png" alt="logo-dark-small"/>
                        <p class="coupon_lbl" >Mapear</p>
                    </div>
                </div>
                <div class="col-sm-4" [routerLink]="'/esbocar'">
                    <div class="coupon_card">
                        <img class="coupon_img"
                             src="assets/images/esbocar1.png" alt="logo-dark-small"/>
                        <p class="coupon_lbl">Esboçar</p>
                    </div>
                </div>
                <div class="col-sm-4" [routerLink]="'/decidir'">
                    <div class="coupon_card">
                        <img class="coupon_img"
                             src="assets/images/decide1.png" alt="logo-dark-small"/>
                        <p class="coupon_lbl">Decidir</p>
                    </div>
                </div>
                <div class="col-sm-4" [routerLink]="'/prototipar'">
                    <div class="coupon_card">
                        <img class="coupon_img"
                             src="assets/images/proto1.png" alt="logo-dark-small"/>
                        <p class="coupon_lbl">Prototipar</p>
                    </div>
                </div>
                <div class="col-sm-4" [routerLink]="'/testar'">
                    <div class="coupon_card">
                        <img class="coupon_img"
                             src="assets/images/test1.png" alt="logo-dark-small"/>
                        <p class="coupon_lbl">Testar</p>
                    </div>
                </div>
            </div>
        </div>
        <!--<div class="col-12">
            <div class="row align-items-center mb-3">
                <div class="col">
                    <h2 class="standardization_title">Prêmios</h2>
                </div>
                <div class="col-auto">
                    <a class="row align-items-center mx-n1" routerLink="/premios">
                        <div class="col-auto px-1">
                            <p class="home-news_lbl mb-0">Veja mais</p>
                        </div>
                        <div class="col-auto px-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="6.421" height="10.918"
                                 viewBox="0 0 6.421 10.918">
                                <g id="Icon_ionic-ios-arrow-dropleft" data-name="Icon ionic-ios-arrow-dropleft"
                                   transform="translate(-7.52 -4.501)">
                                    <path id="Caminho_56" data-name="Caminho 56"
                                          d="M12.436,10.252a.928.928,0,0,1,1.308,0l4.57,4.584a.923.923,0,0,1,.029,1.274l-4.5,4.517a.923.923,0,1,1-1.308-1.3l3.822-3.88L12.431,11.56A.924.924,0,0,1,12.436,10.252Z"
                                          transform="translate(-4.642 -5.481)" fill="#032246"/>
                                </g>
                            </svg>
                        </div>
                    </a>
                </div>
            </div>
            <p class="home_text pr-lg-5 mr-lg-5">
                Todas as revendas com fachada que comprovarem a padronização de sua fachada e frota, ganham cupons para
                o sorteio de até R$ 10 mil em carga de gás, de acordo com sua faixa.
            </p>
            <div class="row mt-4 no-gutters flex-sm-nowrap">

            </div>
            <p class="home_text mt-4"><strong>Atenção:</strong> Só concorrem ao sorteio de padronização as revendas que
                possuem fachadas. Revendas sem fachada (exemplo de postos de combustíveis e supermercados) não concorrem
                ao sorteio.</p>
        </div>-->
    </div>
</div>

<div class="modal fade" id="isViewVideo" tabindex="-1" role="dialog"
     aria-labelledby="isValidateLabel" aria-hidden="true"
     data-backdrop="static">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="row no-gutters">
                <div class="col-sm-4 p-3 bg-primary d-flex align-items-end">
                    <img class="modal_logo modal_logo-kitchen" src="assets/images/logo-liquigas-large-white.png"
                         alt="logo">
                </div>
                <div class="col">
                    <div class="modal-body">
                        <h3 class="kitchen_subtitle text-uppercase mb-4">O  Design Sprint ESTÁ NO AR!</h3>
                        <p class="kitchen_text mb-4">
                            Assista ao vídeo para conferir as novidades, as mecânicas e os prêmios para entrar na campanha com todo gás.</p>
                        <div class="w-100 d-block">
                            <div class="box-video-frame"></div>
                            <!--<iframe width="100%" height="280" src="https://www.youtube.com/embed/hg_gO7eefLg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
                        </div>
                        <div class="row mt-4">
                            <div class="col-sm-6">
                                <a href="javascript:void('');" data-dismiss="modal" aria-label="Close" class="btn btn-block btn-secondary" (click)="actionCookies()">Fechar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
