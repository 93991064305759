import {AfterViewInit, Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {BaseComponent} from '../../base/base.component';
import {NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {
    AuthType,
    CurrentUserGQL,
    CurrentUserQuery,
    User,
    // UserSaveAcceptDeliveryGQL,
    // UserVerifyAcceptDeliveryGQL
} from '../../../generated/graphql';
import {QueryRef} from 'apollo-angular';

declare var $: any;

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent extends BaseComponent implements OnInit, AfterViewInit {
    // arrPageInside = ['/time-preparado', '/ranking-list-extra', '/usuarios', '/campanha', '/ganhadores', '/visita-tecnica', '/premios', '/ranking', '/ranking-detalhe', '/resgate', '/downloads', '/noticias', '/contato', '/faq', '/noticias', '/noticias-detalhe', '/politica', '/lgpd', '/regulamento', '/padronizacao'];
    /*arrPageInside = [
        '/time-preparado',
        '/ranking-list-extra',
        '/usuarios',
        '/campanha',
        '/ganhadores',
        '/premios',
        '/ranking',
        '/ranking-detalhe',
        '/downloads',
        '/noticias',
        '/contato',
        '/faq',
        '/noticias',
        '/noticias-detalhe',
        '/politica',
        '/lgpd',
        '/regulamento',
        '/artigos',
        '/artigos-detalhe',
        '/cases',
        '/cases-detalhe',
        '/sobre',
        '/historia',
        '/jake-knapp',
        '/jakeknapp',
        '/livro',
        '/prototipar',
        '/testar',
        '/decidir',
        '/esbocar',
        '/mapear',
        '/organizar'
    ];*/
    arrPageInside = ['/'];
    user: User = new User();
    authType: AuthType = new AuthType();
    currentUserQuery: QueryRef<CurrentUserQuery>;
    isValidAccept: boolean = false;

    constructor(public router: Router,
                public translate: TranslateService,
                private currentUserGQL: CurrentUserGQL,
                // private userVerifyAcceptDeliveryGQL: UserVerifyAcceptDeliveryGQL,
                // private userSaveAcceptDeliveryGQL: UserSaveAcceptDeliveryGQL,
                public userService: UserService) {
        super(router, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    this.user = data as User;
                }
            }, error: err => super.onError(err)
        });

        moment.locale('pt-br');
        if (this.userService.getStatusAuthenticated()) {
            this.getCurrentUser();
        } else {
            localStorage.clear();
            this.userService.isAuthenticated = false;
        }

        this.router.events.forEach(item => {
            if (item instanceof NavigationEnd) {
                this.handlerHeaderClass(item.url);
            }
        });
        this.handlerHeaderClass(window.location.pathname);
    }

    ngAfterViewInit(): void {
    }

    getCurrentUser() {
        this.currentUserQuery = this.currentUserGQL.watch();
        this.currentUserQuery.valueChanges.subscribe(({data}) => {
            this.user = data.currentUser as User;
            this.authType.user = Object.assign({}, data.currentUser as User);
            // se for entregador
            /*if (!this.isNullOrUndefined(this.user.resaleJobRole)
                && this.user.resaleJobRole.id === 'FF0DEBFB-7C68-4184-AB85-D723EF37F145') {
                this.validateAccept();
            }*/
            this.userService.updateUser(this.user);
        });
    }

    handlerHeaderClass(s: string) {
        if (!this.arrPageInside.includes(this.getRoute(s))) {
            $('body').addClass('body-inside');
        } else {
            $('body').removeClass('body-inside');
        }
    }

    getRoute(s: string) {
        const t = s.split('/');
        return '/' + t[1];
    }

    handlerCurrent() {
        this.currentUserQuery.refetch().then();
    }

    validateAccept() {
        /*this.userVerifyAcceptDeliveryGQL.watch().valueChanges.subscribe(({data}) => {
            if (data.userVerifyAcceptDelivery.status !== 1) {
                this.showModal('isValidate');
            }
        }, error => super.onError(error));*/
    }

    acceptTerms() {
        console.log(this.isValidAccept);
        if (!this.isValidAccept) {
            return this.showMessage('Atenção', `Precisa aceitar os termos para prosseguir`);
        }
        /*this.userSaveAcceptDeliveryGQL.mutate().subscribe(({data}) => {
            this.closeModal('isValidate');
            this.showMessage('Sucesso!', data.userSaveAcceptDelivery.message, 'success');
        }, error => super.onError(error));*/
    }
}
