import { Component, OnInit } from '@angular/core';

declare var $ : any;
@Component({
  selector: 'app-organize',
  templateUrl: './organize.component.html',
  styleUrls: ['./organize.component.scss']
})
export class OrganizeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  handlerCollapse(id: string) {
    $('#' + id).collapse('toggle');
  }

}
