import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vote-cp',
  templateUrl: './vote-cp.component.html',
  styleUrls: ['./vote-cp.component.scss']
})
export class VoteCpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
