<div class="footer mt-5">
    <div class="container-xxl">
        <div class="row my-3" style="row-gap: 20px">
            <div class="col-sm-2 d-flex flex-column justify-content-between">
                <div class="row no-gutters h-100">
                    <strong>Design Sprint</strong>
                    <div class="col-12 col-md-12">
                        <a class="footer_value" [routerLink]="['/sobre']" href="javascript:void('')">Sobre</a>
                    </div>
                    <div class="col-12 col-md-12">
                        <a class="footer_value" href="javascript:void('')" [routerLink]="['/historia']">História</a>
                    </div>
                    <div class="col-12 col-md-12">
                        <a class="footer_value" href="javascript:void('')" [routerLink]="['/jakeknapp']">
                            Jake Knapp
                        </a>
                    </div>
                    <div class="col-12 col-md-12">
                        <a class="footer_value" href="javascript:void('')" [routerLink]="['/livro']">
                           Livro
                        </a>
                    </div>
                    <div class="col-12 col-md-12">
                        <a class="footer_value " href="javascript:void('')" [routerLink]="['/faq']">Perguntas Frequentes</a>
                    </div>
                </div>
            </div>
            <div class="col-sm-2 d-flex flex-column justify-content-between">
                <div class="row no-gutters h-100">
                    <strong>Etapas</strong>
                    <div class="col-12 col-md-12">
                        <a class="footer_value" [routerLink]="['/organizar']" href="javascript:void('')">Organizar</a>
                    </div>
                    <div class="col-12 col-md-12">
                        <a class="footer_value" href="javascript:void('')" [routerLink]="['/mapear']">Mapear</a>
                    </div>
                    <div class="col-12 col-md-12">
                        <a class="footer_value" href="javascript:void('')" [routerLink]="['/esbocar']">
                            Esboçar
                        </a>
                    </div>
                    <div class="col-12 col-md-12">
                        <a class="footer_value" href="javascript:void('')" [routerLink]="['/decidir']">
                            Decidir
                        </a>
                    </div>
                    <div class="col-12 col-md-12">
                        <a class="footer_value" href="javascript:void('')" [routerLink]="['/prototipar']">
                            Prototipar
                        </a>
                    </div>
                    <div class="col-12 col-md-12">
                        <a class="footer_value " href="javascript:void('')" [routerLink]="['/testar']">Testar</a>
                    </div>
                </div>
            </div>
            <div class="col-sm-2 d-flex flex-column justify-content-between">
                <div class="row no-gutters h-100">
                    <strong>Serviços</strong>
                    <div class="col-12 col-md-12">
                        <a class="footer_value"  >Curso Online</a>
                    </div>
                    <div class="col-12 col-md-12">
                        <a class="footer_value" >Workshop presencial</a>
                    </div>
                    <div class="col-12 col-md-12">
                        <a class="footer_value"  >
                            Eventos
                        </a>
                    </div>
                    <div class="col-12 col-md-12">
                        <a class="footer_value" >
                            Facilitação
                        </a>
                    </div>
                    <div class="col-12 col-md-12">
                        <a class="footer_value"  >
                            Palestras
                        </a>
                    </div>
                    <div class="col-12 col-md-12">
                        <a class="footer_value "  >Training</a>
                    </div>
                </div>
            </div>
            <div class="col-sm-2 d-flex flex-column justify-content-between">
                <div class="row no-gutters h-100">
                    <strong>Mais</strong>
                    <div class="col-12 col-md-12">
                        <a class="footer_value" [routerLink]="['/contato']" href="javascript:void('')">Contato</a>
                    </div>
                    <div class="col-12 col-md-12">
                        <a class="footer_value" href="javascript:void('')" [routerLink]="['/artigos']">Artigos</a>
                    </div>
                    <div class="col-12 col-md-12">
                        <a class="footer_value" href="javascript:void('')" [routerLink]="['/cases']">
                            Cases
                        </a>
                    </div>
                    <div class="col-12 col-md-12">
                        <a class="footer_value" >
                            Marca
                        </a>
                    </div>
                    <div class="col-12 col-md-12">
                        <a class="footer_value"  >
                            Sala de Impressa
                        </a>
                    </div>

                </div>
            </div>
            <div class="col-sm-2">
                <dl class="row w-100">
                    <dt class="col-12 footer_lbl">DESIGN SPRINT</dt>
                    <dd class="col-12 footer_value">O método usado no Google para testar e aplicar novas ideias em apenas cinco dias.</dd>
                </dl>
                <div class="divider">&nbsp;</div>
                <dl class="row align-items-center" style="row-gap: 5px">
                    <dt class="col-lg-5 footer_value mb-0">Visite nossas redes sociais</dt>
                    <dd class="col-lg-7 mb-0">
                        <div class="row justify-content-md-around mx-n1">
                            <div class="col-auto px-1">
                                <a href="https://www.facebook.com/igordosanjospro" target="_blank">
                                    <img src="assets/images/facebook.png" alt="facebook">
                                </a>
                            </div>
                            <div class="col-auto px-1">
                                <a href="https://www.linkedin.com/in/igordosanjos/" target="_blank">
                                    <img src="assets/images/linkedin.png" alt="linkedin">
                                </a>
                            </div>
                            <div class="col-auto px-1">
                                <a href="https://www.instagram.com/igordosanjos/" target="_blank">
                                    <img src="assets/images/instagram.png" alt="linkedin">
                                </a>
                            </div>
                        </div>
                    </dd>
                </dl>
            </div>
            <div class="col-sm-2 d-flex align-items-center justify-content-center">
                <img src="assets/images/logo-dark-small.png" alt="logo">
            </div>
        </div>
    </div>
</div>

