import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-not-member',
    templateUrl: './not-member.component.html',
    styleUrls: ['./not-member.component.scss']
})
export class NotMemberComponent extends BaseComponent implements OnInit {

    constructor(public router: Router,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngOnInit(): void {
    }

}
