import {Component, OnDestroy, AfterContentInit, AfterViewInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {
    SearchUserInput,
    User,
    UserPage
} from '../../../generated/graphql';

import {QueryRef} from 'apollo-angular';
import {UserService} from "../../service/user.service";
import moment from 'moment';

declare var $: any;

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent extends BaseComponent implements AfterViewInit, OnDestroy {
    user: User = new User();
    modelUser: User = new User();
    userPage: UserPage = new UserPage();
    // searchUsersQuery: QueryRef<SearchUsersQuery>;
    searchUserInput: SearchUserInput = new SearchUserInput();
    // profiles: Profile[] = [];
    profileId: string;
    // resaleJobRoles: ResaleJobRole[] = [];
    resaleJobRoleId: string;
    emailConfirm: string;

    modelPhoneType = 'celular';

    constructor(public router: Router,
                public translate: TranslateService,
                public userService: UserService,
                // public searchUsersGQL: SearchUsersGQL,
                // public activateUserGQL: ActivateUserGQL,
                // public createUserGQL: CreateUserGQL,
                // public updateUserGQL: UpdateUserGQL,
                // public profilesGQL: ProfilesGQL,
                // public inactiveUserGQL: InactiveUserGQL,
                // public resaleJobRolesGQL: ResaleJobRolesGQL,
    ) {
        super(router, translate);
    }

    ngAfterViewInit() {
        this.userService.user.subscribe({
            next: d => {
                if (!this.isNullOrUndefined(d.id)) {
                    if (this.getModelUserView(d) !== 'rv') {
                        this.router.navigate(['/']).then();
                    } else {
                        this.user = d as User;
                        this.searchUserInput.page = 1;
                        /*this.searchUsersQuery = this.searchUsersGQL.watch({input: this.searchUserInput});
                        this.searchUsersQuery.valueChanges.subscribe(({data}) => {
                            this.userPage = data.searchUsers as UserPage;
                        }, error => super.onError(error));

                        this.resaleJobRolesGQL.watch().valueChanges.subscribe(({data}) => {
                            this.resaleJobRoles = data.resaleJobRoles as ResaleJobRole[];
                        }, error => super.onError(error));

                        this.profilesGQL.watch().valueChanges.subscribe(({data}) => {
                            this.profiles = data.profiles as Profile[];
                        }, error => super.onError(error));*/
                    }
                }
            }, error: err => super.onError(err)
        });
    }

    ngOnDestroy(): void {
        const elModalUser = $('#modalUser');
        if (elModalUser[0]) {
            elModalUser.remove();
        }
    }

    actionDelete(id: string) {
        super.confirmMessage('Atenção', 'Deseja realmente inativar o funcionário?', () => {
            /*this.inactiveUserGQL.mutate({input: {id: id}}).subscribe(({data}) => {
                this.searchUsersQuery.refetch({input: this.searchUserInput}).then();
                this.showMessage('Sucesso', 'Funcionário inativado com  sucesso!', 'success');
            }, error => super.onError(error));*/
        });
    }

    actionNew() {
        this.modelUser = new User();
        this.profileId = undefined;
        this.emailConfirm = undefined;
        this.modelPhoneType = 'celular';
        this.showModal('modalUser');
    }

    pageChanged(event: number) {
        this.searchUserInput.page = event;
        // this.searchUsersQuery.refetch({input: this.searchUserInput}).then();
    }

    activeUser(user: User, event: any) {
        /*this.activateUserGQL.mutate({input: {id: user.id}}).subscribe(({data}) => {
            this.searchUsersQuery.refetch({input: this.searchUserInput}).then();
            // this.actionEdit(user);
            this.showMessage('Sucesso', 'Funcionário validado com  sucesso!', 'success');
        }, error => super.onError(error));*/
    }

    actionEdit(user: User) {
        this.showModal('modalUser');
        this.modelUser = Object.assign({}, user);
        this.modelPhoneType = this.modelUser.phone.length > 10 ? 'celular' : 'fixo';
        // this.profileId = this.modelUser.profile.id;
        // this.resaleJobRoleId = this.modelUser.resaleJobRole?.id;
        this.emailConfirm = undefined;
    }

    onSave() {
        const arrValidateFields = [
            {value: this.resaleJobRoleId, text: `Cargo*<br>`},
            {value: this.modelUser.cpf, text: `CPF <br>`},
            {value: this.modelUser.name, text: `Nome Completo*<br>`},
            {value: this.modelUser.birthday, text: `Data de Nascimento <br>`},
            {value: this.modelUser.phone, text: `Telefone com DDD*<br>`},
            {value: this.modelUser.email, text: `E-mail <br>`},
            // {value: this.profileId, text: `Aréa*<br>`},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção',
                `Preencha os campos obrigatórios corretamente: <br> ${stringError}`);
            return;
        }

        if (Math.floor(moment(new Date()).diff(moment(this.modelUser.birthday, 'DD/MM/YYYY'), 'years', true)) < 18) {
            this.showMessage('Atenção', `É permitido apenas cadastro de maiores de 18 anos.`);
            return;
        }
        if (!super.isCPFValid(this.modelUser.cpf)) {
            this.showMessage('Atenção', 'Informe um CPF válido para prosseguir.', 'warning');
            return;
        }
        if (!super.isEMailValid(this.modelUser.email)) {
            this.showMessage('Atenção', 'Informe um e-mail válido para prosseguir.', 'warning');
            return;
        }
        if (this.isNullOrUndefined(this.emailConfirm)) {
            this.showMessage('Atenção', `Confirme o E-mail para prosseguir`);
            return;
        }

        if (this.modelUser.email != this.emailConfirm) {
            this.showMessage('Atenção', `E-mails não confere!`);
            return;
        }

        if (this.isNullOrUndefined(this.modelUser.id)) {
            this.onCreate();
        } else {
            this.onUpdate();
        }
    }

    onCreate() {
        /*const d: InsertUserInput = {
            name: this.modelUser.name,
            birthDate: moment(this.modelUser.birthday, 'DD/MM/YYYY').utc(false).format('DD/MM/YYYY'),
            cpfCnpj: this.modelUser.cpf,
            email: this.modelUser.email,
            // fatherCode: this.user.fatherCode,
            phone: this.modelUser.phone,
            profileId: 'EQ', // this.profileId, // **** VALOR FIXO PARA CADASTRO DE EQUIPE
            resaleJobRoleId: this.resaleJobRoleId
        };
        this.createUserGQL.mutate({input: d}).subscribe(({data}) => {
            const a: any = data;
            if (a.errors) {
                this.showMessage('Atenção', a.errors[0].message, 'warning');
                return;
            }
            this.closeModal('modalUser');
            this.showMessage('Sucesso', 'Funcionário criado com  sucesso!', 'success');
            this.searchUsersQuery.refetch({input: this.searchUserInput}).then();
        }, error => super.onError(error));*/
    }

    onUpdate() {
        /*const d: UpdateUserInput = {
            id: this.modelUser.id,
            name: this.modelUser.name,
            birthday: moment(this.modelUser.birthday, 'DD/MM/YYYY').utc(false).format('DD/MM/YYYY'),
            email: this.modelUser.email,
            phone: this.modelUser.phone,
        };
        this.updateUserGQL.mutate({input: d}).subscribe(({data}) => {
            this.closeModal('modalUser');
            this.showMessage('Sucesso', 'Funcionário editado com  sucesso!', 'success');
            this.searchUsersQuery.refetch({input: this.searchUserInput}).then();
        }, error => super.onError(error));*/
    }
}
