import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {NewsGQL, NewsPage, NewsQuery} from "../../../generated/graphql";
import {QueryRef} from "apollo-angular";
import {environment} from "../../../environments/environment";

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss']
})
export class NewsComponent extends BaseComponent implements OnInit {
    newsQuery: QueryRef<NewsQuery>;
    // page = 1;
    newsPage: NewsPage = new NewsPage();

    currentPage = 1;
    labels: any = {
        previousLabel: '',
        nextLabel: '',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };

    constructor(public router: Router,
                public newsGQL: NewsGQL,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngOnInit(): void {
        this.searchData();
    }

    searchData(): void {
        this.newsQuery = this.newsGQL.watch({page: this.currentPage, type: 'news'});
        this.newsQuery.valueChanges.subscribe(({data}) => {
            this.newsPage = data.news as NewsPage;
        }, error => super.onError(error));
    }

    pageChanged(event) {
        this.currentPage = event;
        this.searchData();
    }

    /*onScrollDown(ev: any) {
        this.page = this.page + 1;
        if (this.page > this.newsPage.totalPage) {
            return;
        }
        this.newsQuery.refetch({page: this.page}).then(({data}) => {
            const results = data.news as NewsPage;
            this.newsPage.currentPage = results.currentPage;
            this.newsPage.nextPage = results.nextPage;
            this.newsPage.PageSize = results.PageSize;
            this.newsPage.PreviouPage = results.PreviouPage;
            this.newsPage.totalCount = results.totalCount;
            this.newsPage.totalPage = results.totalPage;
            for (const i of this.newsPage.news) {
                this.newsPage.news.push(i);
            }
        });
    }*/

}
