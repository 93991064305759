<!--<app-header *ngIf="getAuthenticated()"></app-header>-->

<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg text mb-0">Politica de privacidade</p>
        <div class="container">
            <h2 class="page_header_title">Regulamento</h2>
        </div>
    </div>
</div>


<div class="container">
    <div class="d-block text-right">
        <a routerLink="/" class="btn btn-outline-secondary back_button my-3">
            <div class="d-flex align-items-center" style="gap: 20px">
                <svg-icon src="assets/svg/arrow.svg"></svg-icon>
                <p class="mb-0">Voltar</p>
            </div>
        </a>
    </div>

    <div class="regulation">
        <div class="regulation_top">
            <p class="text-primary"> <strong>Programa de Incentivo<br>
                 - Design Sprint<br>
                REVENDEDOR E EQUIPE DAS REVENDAS Design Sprint</strong><br>
                Design SprintDISTRIBUIDORA S.A.<br>
                CNPJ nº 60.886.413/0001-47</p>

            <a href="/assets/files/desafio-liquigas-regulamento.pdf" class="btn-download" download target="_blank">
                <span class="span">&nbsp;</span>
                <span>Download</span>
                <svg-icon src="assets/svg/download.svg"></svg-icon>
            </a>
        </div>
    </div>
    <div class="regulation_text">
        <ng-container [ngTemplateOutlet]="regulamento"></ng-container>
    </div>
</div>


<ng-template #regulamento>
    <h2>1.	DO PROGRAMA</h2>
    <p>1.1.	O Programa de Incentivo &quot; - Design Sprint&quot; (&quot;Programa&quot;) é realizado pela Design SprintDISTRIBUIDORA S.A., (&quot;Realizadora&quot; ou “Design Sprint”), com sede em Avenida Paulista, 1842, 3º (parte), 4º, 5º e 6º andares, Torre Norte, Edif. Cetenco Plaza, Bela Vista, na Cidade de São Paulo - SP.</p>
    <p>1.2.	A finalidade deste Programa é incentivar as vendas dos produtos Design Sprint, bem como a melhoria na gestão e atendimento das Revendas com a finalidade de fidelizar os clientes e alcançar melhores resultados e, em consequência, aumentar a participação e o engajamento de toda a cadeia de Revendedores Design Sprintno Desafio.</p>
    <p>1.3.	Para tanto, este Programa possui duas vertentes de atuação: recompensa aos participantes pelos resultados obtidos, e recompensa aos participantes pelo engajamento com os objetivos da Realizadora.</p>
    <p>1.4.	O presente Regulamento tem por objetivo estabelecer as diretrizes e orientações para o &quot; – Design Sprint”, especificamente na categoria &quot;Revendedor e Equipe das Revendas Design Sprint&quot;.</p>
    <h2>2.	PERÍODO DE DURAÇÃO DO PROGRAMA</h2>
    <p>2.1.	O presente Programa terá início no dia 01 de outubro de 2023, encerrando-se em 28 de fevereiro de 2023.</p>
    <p>2.2.	De 19 de setembro de 2023 a 01 de outubro de 2023, as Revendas poderão conhecer as mecânicas, metas, premiações e realizar o pré cadastro de seus funcionários.</p>
    <h2> 3.	QUEM PARTICIPA</h2>
    <p>3.1.	REVENDEDOR Design SprintE SUA EQUIPE</p>
    <p>3.1.1.	Este Programa é direcionado exclusivamente aos Revendedores Design Sprintque aderiram ao Fundo de Marketing até o dia 31 de agosto de 2023 (“Revenda” ou “Revendedor”) e a todos os seus funcionários pessoas físicas maiores de 18 (dezoito anos), residentes e domiciliadas na área de execução do Programa (“Equipe”).</p>
    <p>3.1.2.	Entendem-se por &quot;Equipe&quot; os funcionários do Revendedor, tais como gerente e supervisor da Revenda, atendentes, entregadores e motoristas, auxiliares administrativos e outros, desde que cadastrados no site do Programa, até o dia 15/02/2023, viabilizando que os funcionários participem do último treinamento, conforme previsto no presente regulamento, disponível em www.desafioliquigas.com.br.</p>
    <p>3.1.3.	Será excluído do Programa o funcionário da Revenda que for desligado de suas atividades durante o Programa e/ou durante a fase de premiação, mesmo que tenha trabalhado até  o período de apuração e fechamento dos pontos relativos ao desempenho da Revenda em que atuava. A responsabilidade pela ativação e desativação do funcionário ficará a cargo do Revendedor responsável pelo cadastro da Equipe.<br>
        O Revendedor responsável pelo cadastro da Equipe fica ciente de que caso o funcionário não seja inativado, este continuará participando, o que terá influência sobre os resultados, considerando que algumas pontuações apenas serão atribuídas se houver a participação de todos os funcionários da Revenda e que cada funcionário representa um percentual no atingimento da meta.</p>
    <p>3.1.4.	A Realizadora verificará, antes da entrega dos prêmios, se a equipe cadastrada continua atuando na Revenda.</p>
    <p>3.1.5.	Os Revendedores participantes do Programa estão divididos por região, sendo que cada região está agrupada por Gerência de Mercado Regional (GMR), no total de 4 (quatro) regiões, conforme divisão da Realizadora que segue abaixo:</p>
    <ul>
        <li><strong>REGIÃO 1:</strong> GMR/NO (Amapá, Ceará, Maranhão, Pará, Piauí).</li>
        <li><strong>REGIÃO 2:</strong> GMR/MG (Goiás, Minas Gerais), GMR/SPI (Minas Gerais, São Paulo) e GMR/SPM (São Paulo).</li>
        <li> <strong>REGIÃO 3:</strong> GMR/CO (Distrito Federal, Goiás, e Tocantins), GMR/LE (Espírito Santo, Minas Gerais e Rio de Janeiro) e GMR/NE (Alagoas, Bahia, Paraíba, Pernambuco, Rio Grande do Norte, Sergipe).</li>
        <li> <strong>REGIÃO 4:</strong> GMR/SUL (Paraná, Rio Grande do Sul e Santa Catarina).*</li>
    </ul>
    <p>* Consulte sua Região no site da campanha www.desafioliquigas.com.br no campo “Meu perfil”.</p>
    <p>3.1.6.	Além do agrupamento por região também haverá uma divisão por   Faixa conforme volume de compras de GLP para equalizar a competitividade por Faixa. Para o cálculo da equivalência em botijões P13, foi considerada a média mensal de vendas de GLP (kg) de todos os recipientes, no período de fevereiro/2022 a agosto/2022. e dividido o valor total por 13 kg.</p>
    <table border="0" cellspacing="0" cellpadding="0">
        <tr>
            <td><p><strong>FAIXAS</strong></p></td>
            <td><p><strong>QUANT P-13 / MÊS</strong></p></td>
        </tr>
        <tr>
            <td><p>FAIXA 1</p></td>
            <td><p>ATÉ 199 </p></td>
        </tr>
        <tr>
            <td><p>FAIXA 2</p></td>
            <td><p>DE 200 À 799 </p></td>
        </tr>
        <tr>
            <td><p>FAIXA    3</p></td>
            <td><p>DE    800 À 2.099 </p></td>
        </tr>
        <tr>
            <td><p>FAIXA    4</p></td>
            <td><p>DE    2.100 À 5.999 e</p></td>
        </tr>
        <tr>
            <td><p>FAIXA    5</p></td>
            <td><p>A    PARTIR DE 6.000 </p></td>
        </tr>
    </table>
    <p>3.1.6.1.	Cada Revendedor concorrerá com outros Revendedores que estão dentro da mesma Faixa e da respectiva Região.</p>
    <p>3.1.6.2.	Caso o Revendedor discorde da classificação de Faixas, ele poderá encaminhar e-mail até 14/10/2022 para sac@designsprint.com.br, com as justificativas técnicas para a sua reclassificação, que serão analisadas pela Design Sprint.</p>
    <p>3.1.6.2.1.	A Design Sprintanalisará até 28/10/2022 a solicitação de revisão de classificação de Faixas do Revendedor. Não haverá recurso sobre a decisão final da Realizadora.</p>
    <p>3.1.6.3.	Após o transcurso do prazo estipulado no item 2.1.4.3, não será aceita pela Design Sprintnenhuma solicitação de reclassificação, devendo o Revendedor permanecer na Faixa em que foi inicialmente classificado.</p>
    <h2>4.	COMO PARTICIPAR</h2>
    <p>4.1.	REVENDEDOR:</p>
    <p>4.1.1.	Todos os Revendedores vinculados que estão com a adesão confirmada no Fundo de Marketing até o dia 31/08/2022 estão habilitados a participar do Programa.<br>
        Os Revendedores deverão acessar o site do Programa www.desafioliquigas.com.br  a partir do dia 19/09/2022, preencher o campo &quot;login&quot; com o número do CNPJ do Revendedor e o campo &quot;senha&quot; com os 4 (quatro) primeiros dígitos do CNPJ. Em seguida, deverão conferir e atualizar as seguintes informações de cadastro: nome completo, endereço de e-mail válido, CPF, número do telefone celular, data de nascimento e cargo. Após o primeiro acesso, a senha deverá ser trocada pelo usuário.</p>
    <p>4.1.1.1.	Na sequência, o Revendedor deverá dar o aceite no Regulamento do Programa e nos Termos da Política de Privacidade.</p>
    <p>4.1.2.	Os Revendedores que aderiram com o Fundo de Marketing até o dia 31/08/2022, mas posteriormente solicitaram cancelamento, serão desclassificados e excluídos do Programa.</p>
    <p>4.1.2.1.	Fica estabelecido que os Revendedores que virarem a bandeira ou se tornarem independentes durante a vigência do Programa, serão excluídos automaticamente do  Programa.</p>
    <p>4.2. EQUIPE REVENDEDOR:</p>
    <p>4.2.1. Os Revendedores e seus gerentes ou supervisores atuarão como agentes multiplicadores e serão responsáveis por cadastrar, ativar, excluir e inativar a Equipe da Revenda no período de 19/09/2022 a 15/02/2023, além de informá-los sobre  o Programa, formas de participação, premiação, ranking, entre outros. No caso de novas admissões e/ou demissões de funcionários, a responsabilidade de atualizar a equipe dentro do período de apuração é de total responsabilidade do revendedor.</p>
    <p>4.2.2. Para que os membros das Equipes das Revendas consigam realizar o primeiro acesso ao site do Programa #, o Revendedor deverá ter ativado os membros da Equipe de Revenda no Programa. O funcionário da Revenda só poderá participar e concorrer no Programa após cadastrado e ativado pelo responsável da Revenda.</p>
    <p>4.2.3. Os membros das Equipes das Revendas deverão acessar o site do Programa #  a partir do dia 19/09/2022 para dar o aceite ao Regulamento, preencher o campo &quot;login&quot; com o número do seu CPF e o campo &quot;senha&quot; com os 4 (quatro) primeiros dígitos do seu CPF e, em seguida, deverão conferir e atualizar suas informações de cadastro como nome completo, endereço de e-mail válido, CPF, número do telefone celular e data de nascimento. Após o primeiro acesso, a senha deverá ser trocada pelo usuário.</p>
    <p>4.2.3.1. No primeiro login, os membros das Equipes das Revendas deverão dar o aceite no Regulamento do Programa e nos Termos da Política de Privacidade.</p>
    <p>4.2.4. Caso o membro da Equipe da Revenda não ative o seu cadastro através do aceite ao Regulamento e Política de Privacidade, não participará do presente Programa, não tendo direito a qualquer premiação. Além disso, caso o cadastro do funcionário seja inativado durante o período de duração do Programa, este será excluído do Programa e não terá direito a qualquer premiação.</p>
    <h2>5.	AVALIAÇÃO, MÉTRICAS E PREMIAÇÃO</h2>
    <p>5.1.	Os Revendedores e sua equipe participantes serão avaliados e, conforme o caso, recompensados pela       atuação nos seguintes grupos de métrica e pontuação:<br>
        -	Desempenho geral (ranking final);<br>
        -	Treinamento da equipe da revenda (mecânica extra).<br>
        -	Padronização da revenda (mecânica extra).</p>
    <h2>6.	CRITÉRIO DE AVALIAÇÃO REVENDEDOR - DESEMPENHO GERAL (RANKING FINAL)</h2>
    <p>6.1. Visando a apuração dos melhores Revendedores,  que farão jus à premiação adiante descrita, a Realizadora adotará o seguinte cálculo, podendo eventualmente, para efeitos de participação no Programa, as Revendas matriz e filial serem agrupadas e concorrerem com apenas 1 (um) CNPJ: Desempenho = ΣΔV + ΣAd + VT + ME</p>
    <p>Eles correspondem respectivamente à <strong>Variação de Vendas + Adimplência +  Visita Técnica + Engajamento.</strong></p>
    <p>Onde:</p>
    <p><strong>ΔV</strong> | Variação de vendas: 0 a 110 pontos (mensal) = 550 pts (máx)<br>
        <strong>Ad</strong> | Adimplência: 0 ou 75 pontos (mensal) = 375 pts (máx)<br>
        <strong>VT</strong> | Visita técnica: 0 a 400 pontos (programa) sendo:</p>
    <p>A)	Gestão Informatizada: 0 ou 50 pontos = 50 pts (máx)<br>
        B)	Treinamento: 0 ou 50 pontos = 50 pts (máx)<br>
        C)	Segurança nas instalações: 0 ou 50 pontos = 50 pts (máx)<br>
        D)	Padronização da Fachada: 0 ou 200 pontos – 200 pts (máx)<br>
        E)	Padronização da Frota: 0 ou 50 pontos –50 pts (máx)</p>
    <p>*Para o atendimento ao item VT, que engloba os itens A, B, C, D e E, a Revenda ou o comercial responsável pela Revenda deverá acessar o site # , devendo informar se a revenda possui ou não fachada (ex: posto de combustível ou supermercado), além de seguir a orientação para envio das fotos/arquivos de imagem que comprovem o atendimento a cada uma das condições aqui estabelecidas.</p>
    <p>ME | Mecânica de Engajamento: 0 a 35 pontos (mensal) = 175 pts (máx)</p>
    <p><strong>PONTUAÇÃO MÁXIMA POR ITEM E POR MÊS</strong></p>
    <table border="0" cellspacing="0" cellpadding="0">
        <tr>
            <td><p><strong>ITEM</strong></p></td>
            <td><p><strong>out/22</strong></p></td>
            <td><p><strong>nov/22</strong></p></td>
            <td><p><strong>dez/22</strong></p></td>
            <td><p><strong>jan/23</strong></p></td>
            <td><p><strong>fev/23</strong></p></td>
            <td><p><strong>TOTAL</strong></p></td>
            <td><p><strong>PESO</strong></p></td>
        </tr>
        <tr>
            <td><p>VARIAÇÃO    DE VENDAS (<strong>ΔV)</strong></p></td>
            <td><p>110</p></td>
            <td><p>110</p></td>
            <td><p>110</p></td>
            <td><p>110</p></td>
            <td><p>110</p></td>
            <td><p>550</p></td>
            <td><p>37%</p></td>
        </tr>
        <tr>
            <td><p>ADIMPLÊNCIA    (Ad)</p></td>
            <td><p>75</p></td>
            <td><p>75</p></td>
            <td><p>75</p></td>
            <td><p>75</p></td>
            <td><p>75</p></td>
            <td><p>375</p></td>
            <td><p>25%</p></td>
        </tr>
        <tr>
            <td><p>VISITA    TÉCNICA (VT)</p></td>
            <td><p>&nbsp;</p></td>
            <td><p>&nbsp;</p></td>
            <td><p>&nbsp;</p></td>
            <td><p>&nbsp;</p></td>
            <td><p>400</p></td>
            <td><p>400</p></td>
            <td><p>27%</p></td>
        </tr>
        <tr>
            <td><p>A.    Gestão Informatizada </p></td>
            <td><p>&nbsp;</p></td>
            <td><p>&nbsp;</p></td>
            <td><p>&nbsp;</p></td>
            <td><p>&nbsp;</p></td>
            <td><p>50</p></td>
            <td><p>&nbsp;</p></td>
            <td><p>&nbsp;</p></td>
        </tr>
        <tr>
            <td><p>B.    Treinamento </p></td>
            <td><p>&nbsp;</p></td>
            <td><p>&nbsp;</p></td>
            <td><p>&nbsp;</p></td>
            <td><p>&nbsp;</p></td>
            <td><p>50</p></td>
            <td><p>&nbsp;</p></td>
            <td><p>&nbsp;</p></td>
        </tr>
        <tr>
            <td><p>C.    Segurança nas instalações</p></td>
            <td><p>&nbsp;</p></td>
            <td><p>&nbsp;</p></td>
            <td><p>&nbsp;</p></td>
            <td><p>&nbsp;</p></td>
            <td><p>50</p></td>
            <td><p>&nbsp;</p></td>
            <td><p>&nbsp;</p></td>
        </tr>
        <tr>
            <td><p>D. Fachada da revenda </p></td>
            <td><p>&nbsp;</p></td>
            <td><p>&nbsp;</p></td>
            <td><p>&nbsp;</p></td>
            <td><p>&nbsp;</p></td>
            <td><p>200</p></td>
            <td><p>&nbsp;</p></td>
            <td><p>&nbsp;</p></td>
        </tr>
        <tr>
            <td><p>E. Frota da revenda </p></td>
            <td><p>&nbsp;</p></td>
            <td><p>&nbsp;</p></td>
            <td><p>&nbsp;</p></td>
            <td><p>&nbsp;</p></td>
            <td><p>50</p></td>
            <td><p>&nbsp;</p></td>
            <td><p>&nbsp;</p></td>
        </tr>
        <tr>

            <td><p>MECÂNICA    DE ENGAJAMENTO (ME)</p></td>
            <td><p>35</p></td>
            <td><p>35</p></td>
            <td><p>35</p></td>
            <td><p>35</p></td>
            <td><p>35</p></td>
            <td><p>175</p></td>
            <td><p>12%</p></td>
        </tr>
        <tr>
            <td><p>&nbsp;</p></td>
            <td><p>&nbsp;</p></td>
            <td><p>&nbsp;</p></td>
            <td><p>&nbsp;</p></td>
            <td><p>&nbsp;</p></td>
            <td><p>&nbsp;</p></td>
            <td><p>1.500</p></td>
            <td><p>100%</p></td>
        </tr>
    </table>
    <p>6.1.1.	A pontuação referente aos itens avaliados será atribuída por uma auditoria independente contratada pela Design Sprintque será responsável por avaliar as fotos imputadas no site pelos pelos Revendedores e/ou Consultores Comerciais e verificar a conformidade em relação a cada critério.</p>
    <table border="0" cellspacing="0" cellpadding="0">
        <tr>
            <td><p><strong>ITEM</strong></p></td>
            <td><p><strong>DESCRIÇÃO</strong></p></td>
            <td><p><strong>PONTOS/MÊS</strong></p></td>
        </tr>
        <tr>
            <td><p>Variação de Vendas (ΔV)</p></td>
            <td><p>Período:    01/10/2022 à 28/02/2023</p>
                <p>Relação entre o volume de vendas e a meta    de vendas estabelecida para o período.</p>
                <p>Cálculo: [(Venda mensal/ Meta mensal de vendas de 0 a 110%) x    100]. Pontos por mês (out/22    à fev/23): 0 a 110 <br>
                    Pontuação    máxima no Programa: 550 </p></td>
            <td><p>0 a 110</p></td>
        </tr>
        <tr>
            <td><p>Adimplência    (Ad) </p></td>
            <td><p>Período:    01/10/2022 à 28/02/2023</p>
                <p>Condição: fica desde já estabelecido que o Revendedor    pontuará apenas se estiver adimplente. Esta condição será verificada através    do relatório de adimplência da Realizadora. </p>
                <p>Apuração: a apuração será feita no último dia de cada mês, sendo    que serão concedidos mais 3 (três) dias úteis para que as Revendas    quitem os títulos em aberto.</p>
                <p>Obs.: considera-se inadimplente para fins deste    Programa o Revendedor cujo    pagamento do débito de qualquer natureza perante a Realizadora esteja    atrasado por 3 (três) dias úteis. <br>
                    Pontos por mês (out/22 à fev/23): 0 a 75 <br>
                    Pontuação    máxima no Programa: 375 </p></td>
            <td><p>0 ou 75</p></td>
        </tr>
        <tr>
            <td><p>Visita Técnica (VT)</p></td>
            <td><p>Período:    01/10/2022 à 10/02/2023</p>
                <ol>
                    <li>Gestão Informatizada (50 pontos). A Revenda pontuará se possuir controles    informatizados da Lista de Clientes ou do Fluxo de Caixa, conforme segue:
                        <ol type="a">
                            <li>Lista de Clientes* cadastrados da Revenda, podendo ser apresentada    fotografia ou print de tela que comprove esses registros através de algum    sistema ou planilha. Não serão aceitas imagens de listas manuais. (25    pontos).</li>
                            <li>Fluxo de Caixa, podendo ser apresentada fotografia ou print de    tela que comprove esses registros através de algum sistema ou planilha. É    necessário que no arquivo conste alguma informação de identificação da Revenda,    como a razão social e/ou CNPJ. (25 pontos).</li>
                        </ol>
                    </li>

                    <li>Treinamento (50 pontos): certificado de treinamento/ cursos com    validade de até 1 ano da data de inserção no site. Serão aceitos treinamentos    realizados pelo proprietário da Revenda ou Equipe da Revenda, sejam eles    ministrados pelo consultor comercial Design Sprintou cursos online ou presenciais    em outras instituições desde que seja em área relacionadas com gestão de    negócios ou similares.</li>
                    <li>Segurança nas Instalações (50 pontos): delimitação da área de    armazenamento de botijões por meio de pintura no piso ou cerca/ gradil    metálico. Se houver botijões fora da área de armazenamento, não pontua. </li>
                    <li>Padronização Revenda (200 pontos): inserir até    4 fotos que comprove a padronização da Revenda matriz e das suas filiais.
                        <ol type="a">
                            <li>Revendas: fotos que comprovem a    correta padronização da fachada da Revenda segundo o manual de identidade    visual da marca Design Sprint. As fotos devem considerar pintura de muros,    portões, gradil e placa de fachada (testeira). Todos os itens não podem    conter a antiga marca da Design Sprint, seja com endosso BR Petrobras, seja com a    grafia antiga. </li>
                            <li>Revendas sem fachada (ex: Postos e    Supermercados): fotos que comprovem a correta    padronização onde houver a marca aplicada: as 2 placas obrigatórias da ANP    (marca e preço) e gaiola verde, se houver. Todos os itens não podem conter a    antiga marca da Design Sprint, seja com endosso BR Petrobras, seja com a grafia    antiga. <br>
                                <br>
                                Caso a Revenda matriz (PAI) tenha filial(is) agrupada(s) que estiver(em)    atrelada(s) ao grupo que compõe o Fundo de Marketing, deverá comprovar a    padronização de cada Revenda filial individualmente.<br>
                                <br>
                            </li></ol>
                    </li>
                    <li>          Padronização Frota** (50 pontos): Inserir    até 20 Imagens que    comprovem a correta padronização da frota da Revenda segundo o manual de    identidade visual da marca Design Sprint, podendo ser pick-up, moto ou caminhão na    parte frontal e lateral. Importante que nas imagens também apareçam as placas    dos veículos, para que fique evidente a padronização de toda a frota, caso a Revenda    possua mais de um veículo de aparência similar. Todos os itens não podem conter a antiga marca da Design Sprint,    seja com endosso BR Petrobras, seja com a grafia antiga. </li>
                </ol>
                <p>Caso a Revenda matriz (PAI) tenha filial(ais) atreladas ao grupo    que compõe o Fundo de Marketing, deverá ser considerada a frota de cada Revenda    individualmente.</p>
                <p>Pontuação máxima no Programa: 400. A    Pontuação referente à VT será dada uma única    vez, e será computada somente no último      ranking). Para pontuar, a Revenda    ou comercial precisará subir as imagens no site <a href="#">#</a> até o dia 10/02/2023.</p>
                <p>As revendas que tiverem a padronização aprovada até 11/12/2022, ganharão um cupom extra no sorteio de padronização.</p></td>
            <td><p>&nbsp;</p>
                <p>0 a 400</p></td>
        </tr>
        <tr>
            <td><p align="center">Mecânica de Engajamento (ME)</p></td>
            <td><p>Período:    01/10/2022 à 28/02/2023</p>
                <ol type="a">
                    <li> O responsável pela    revenda e a equipe, que assistirem ao vídeo    mensal receberá 10    pontos/mês;</li>
                    <li>O responsável pela revenda e a equipe, que responder    corretamente a no mínimo uma pergunta do quiz mensal receberá 10  pontos/mês</li>
                    <li>Ainda, caso todos os funcionários da Revenda cadastrados no    Programa assistirem ao vídeo do    mês e responderem o quiz (independentemente de ter acertado), a Revenda receberá 15 pontos. </li>
                </ol>
                <p>Serão pontuados todos os que cumprirem o  proposto até às 23h59 do último    dia de cada mês.<br>
                    Pontuação    máxima no Programa: 175 </p></td>
            <td><p>0 a 35</p></td>
        </tr>
    </table>

    <p>* No caso de postos de combustíveis e supermercados que atuem como Revenda, a lista de clientes cadastrados não é obrigatória. Esses tipos de Revenda receberão 25 pontos mesmo que não ocorra a inclusão do comprovante correspondente.</p>
    <p>** No caso de postos de combustíveis e supermercados, a padronização de frota não é obrigatória. Esses tipos de Revenda receberão 50 pontos mesmo que não ocorra a inclusão do comprovante correspondente.</p>
    <p>6.2.	A apuração com a posição do Revendedor será feita mensalmente em cada um dos 5 (cinco) meses de Programa, à exceção da VT (onde a pontuação da VT será calculada após o prazo de  upload - 10/02/2023 às 23h59), sendo essa pontuação divulgada juntamente com o ranking final. E quando a empresa obtiver a pontuação da VT, aplicar-se-a a fórmula: ΣΔV + ΣAd + VT + ME de modo a calcular o resultado final, que será uma única vez, sendo que ao  final do Programa será verificada a posição final de cada Revendedor e as premiações correspondentes.</p>
    <p>6.2.1.	Em caso de empate entre os Revendedores, mensalmente a Design Sprintadotará como critério de desempate a maior pontuação referente ao item Variação de Vendas (ΣΔV), sem o limite de 110% (cento e dez por cento).  Persistindo o empate, será adotado como critério de desempate quem obtiver a maior nota em Visita Técnica (VT) e, se ainda assim persistir, será considerado como critério de desempate quem obtiver a maior nota em Adimplência (Ad).</p>
    <h2>7.	DA PREMIAÇÃO POR RANKING FINAL</h2>
    <p>7.1.	Premiação Ranking Final Revendedor: ao final do Programa, será apurada a performance das Revendas e conforme a posição final do Revendedor, este receberá crédito em carteira para adquirir carga de gás (GLP), conforme Quadro abaixo, ficando esclarecido que serão premiadas 200 Revendas (10 Revendas por Faixa e por Região, do 1º ao 10º lugar):</p>
    <table border="0" cellspacing="0" cellpadding="0">
        <tr>
            <td colspan="7"><p><strong>OPÇÕES: CARGA    DE GÁS (CRÉDITO EM CARTEIRA)</strong></p></td>
        </tr>
        <tr>
            <td><p><strong>PREMIAÇÃO</strong></p></td>
            <td><p><strong>FAIXA 1</strong></p></td>
            <td><p><strong>FAIXA 2</strong></p></td>
            <td><p><strong>FAIXA 3</strong></p></td>
            <td><p><strong>FAIXA 4</strong></p></td>
            <td><p><strong>FAIXA 5</strong></p></td>
            <td><p><strong>Regiões</strong></p></td>
        </tr>
        <tr>
            <td><p>1º    lugar</p></td>
            <td><p> R$ 8.000,00 </p></td>
            <td><p> R$ 12.000,00 </p></td>
            <td><p> R$ 18.000,00 </p></td>
            <td><p> R$ 22.500,00 </p></td>
            <td><p> R$ 28.000,00 </p></td>
            <td><p>4</p></td>
        </tr>
        <tr>
            <td><p>2º    lugar</p></td>
            <td><p> R$ 7.000,00 </p></td>
            <td><p> R$ 10.000,00 </p></td>
            <td><p> R$ 15.500,00 </p></td>
            <td><p> R$ 20.000,00 </p></td>
            <td><p> R$ 23.500,00 </p></td>
            <td><p>4</p></td>
        </tr>
        <tr>
            <td><p>3º    lugar</p></td>
            <td><p> R$ 6.000,00 </p></td>
            <td><p> R$ 8.000,00 </p></td>
            <td><p> R$ 13.500,00 </p></td>
            <td><p> R$     18.000,00 </p></td>
            <td><p> R$ 21.500,00 </p></td>
            <td><p>4</p></td>
        </tr>
        <tr>
            <td><p>4º lugar ao 6º lugar</p></td>
            <td><p> R$ 4.000,00 </p></td>
            <td><p> R$ 6.000,00 </p></td>
            <td><p> R$ 11.500,00 </p></td>
            <td><p> R$  16.000,00 </p></td>
            <td><p> R$ 19.500,00 </p></td>
            <td><p>4</p></td>
        </tr>
        <tr>
            <td><p>7º  ao 10º lugar</p></td>
            <td><p> R$ 2.000,00 </p></td>
            <td><p> R$ 4.000,00 </p></td>
            <td><p> R$ 9.000,00 </p></td>
            <td><p> R$  12.500,00 </p></td>
            <td><p> R$ 16.000,00 </p></td>
            <td><p>4</p></td>
        </tr>
    </table>

    <p>7.1.1.	As Revendas vencedoras receberão a premiação em crédito na carteira de cliente da Design Sprintpara a compra de GLP, sendo que o referido crédito poderá ser utilizado para o abatimento de eventuais títulos e juros em aberto.</p>
    <p>7.1.2.	As Revendas ganhadoras no ranking final do 1º ao 10º lugar por Faixa e por região também receberão como forma de reconhecimento por seu desempenho 1 troféu, indicando se sua performace foi ouro (1º lugar), prata (2º lugar) ou bronze (do 3º à 10º lugar).</p>
    <p>7.1.3.	As Revendas que ficarem em 1º lugar no ranking final também serão premiadas com 1 KIT Desafio cada, conforme a tabela abaixo:</p>
    <table border="0" cellspacing="0" cellpadding="0">
        <tr>
            <td colspan="9"><p align="center"><strong>RANKING FINAL - KIT DESAFIO REVENDAS</strong></p></td>
        </tr>
        <tr>
            <td></td>
            <td><p align="center">FAIXA 1</p></td>
            <td><p align="center">FAIXA 2</p></td>
            <td><p align="center">FAIXA 3</p></td>
            <td><p align="center">FAIXA 4</p></td>
            <td><p align="center">FAIXA 5</p></td>
            <td><p align="center">REGIÕES</p></td>
            <td><p align="center">PREMIADOS POR FAIXA</p></td>
            <td><p align="center">QTD PRÊMIOS</p></td>
        </tr>
        <tr>
            <td><p align="left">IMÃS</p></td>
            <td><p align="center">300</p></td>
            <td><p align="center">1.000</p></td>
            <td><p align="center">2.000</p></td>
            <td><p align="center">6.000</p></td>
            <td><p align="center">12.000</p></td>
            <td><p align="center">4</p></td>
            <td><p align="center">1</p></td>
            <td><p align="center">85.200</p></td>
        </tr>
        <tr>
            <td><p align="left">PANOS DE PRATO</p></td>
            <td><p align="center">100</p></td>
            <td><p align="center">400</p></td>
            <td><p align="center">800</p></td>
            <td><p align="center">1.200</p></td>
            <td><p align="center">2.500</p></td>
            <td><p align="center">4</p></td>
            <td><p align="center">1</p></td>
            <td><p align="center">20.000</p></td>
        </tr>
        <tr>
            <td><p align="left">AVENTAL</p></td>
            <td><p align="center">50</p></td>
            <td><p align="center">100</p></td>
            <td><p align="center">150</p></td>
            <td><p align="center">300</p></td>
            <td><p align="center">600</p></td>
            <td><p align="center">4</p></td>
            <td><p align="center">1</p></td>
            <td><p align="center">4.800</p></td>
        </tr>
        <tr>
            <td><p align="left">SACOLA</p></td>
            <td><p align="center">50</p></td>
            <td><p align="center">100</p></td>
            <td><p align="center">150</p></td>
            <td><p align="center">300</p></td>
            <td><p align="center">600</p></td>
            <td><p align="center">4</p></td>
            <td><p align="center">1</p></td>
            <td><p align="center">4.800</p></td>
        </tr>
    </table>

    <p>7.2.	Premiação Ranking Final Equipe Revendedor: os funcionários das 200 (duzentas) Revendas vencedoras do ranking ganharão, cada um, 1 (um) crédito na carteira digital  (conta de pagamento pré-paga de titularidade do contemplado), no valor de R$ 300,00 (trezentos reais).A conta na carteira digital  deverá ser criada conforme informações a seguir: <br>
        Para criar sua conta , basta seguir o passo a passo a seguir: acesse o Google Play ou App Store, dependendo de qual for o modelo do seu celular; clique em &quot;Criar minha conta no &quot;; preencha suas informações pessoais (nome completo, e-mail, CPF, data de nascimento e número de telefone); durante o cadastro, poderá ser solicitado o envio de uma foto do seu rosto (selfie) também, para análise biométrica; crie sua senha de acesso; crie seu nome de usuário; finalize seu cadastro e pronto! Agora você possui uma conta no .</p>
    <p>7.2.1.	Fica estabelecido que somente farão jus ao prêmio os funcionários das Revendas vencedoras, desde que o Revendedor tenha cadastrado e ativado o funcionário no Programa até um mês antes do encerramento do Programa, ou seja, até dia 15/02/2023. Ainda, os funcionários deverão ter dado o aceite no regulamento e termos de privacidade no site para ter direito à premiação, bem como devem estar trabalhando na Revenda para fazer jus ao prêmio.</p>
    <h2>8.	DA PREMIAÇÃO POR TREINAMENTO DA EQUIPE (MECÂNICA EXTRA)</h2>
    <p>8.1.	As Revendas também serão avaliadas por engajamento dos seus funcionários, com os processos colocados à sua disposição, conforme segue:</p>
    <p>8.2.	TIME PREPARADO: no período de outubro/2022 a fevereiro/2023, serão considerados para avaliação dos membros da Equipe cadastrados no Programa os itens abaixo descritos, de acordo com o mês de realização do Programa:</p>
    <table border="0" cellspacing="0" cellpadding="0">
        <tr>
            <td rowspan="2"><p>&nbsp;</p>
                <p><strong>ITEM</strong></p></td>
            <td colspan="5"><p><strong>PONTUAÇÃO MÁXIMA</strong></p></td>
        </tr>
        <tr>
            <td><p><strong>PONTOS</strong><br>
                <strong>Outubro/2022</strong></p></td>
            <td><p><strong>PONTOS</strong><br>
                <strong>Novembro/2022</strong></p></td>
            <td><p><strong>PONTOS</strong><br>
                <strong>Dezembro/2022</strong></p></td>
            <td><p><strong>PONTOS</strong><br>
                <strong>Janeiro/2023</strong></p></td>
            <td><p><strong>PONTOS</strong><br>
                <strong>Fevereiro/2023</strong></p></td>
        </tr>
        <tr>
            <td><p>ASSISTIR AO VÍDEO<br>
                (INDIVIDUAL)</p></td>
            <td><p>10</p></td>
            <td><p>10</p></td>
            <td><p>10</p></td>
            <td><p>10</p></td>
            <td><p>10</p></td>
        </tr>
        <tr>
            <td><p>ACERTAR O QUIZ<br>
                (INDIVIDUAL)</p></td>
            <td><p>10</p></td>
            <td><p>10</p></td>
            <td><p>10</p></td>
            <td><p>10</p></td>
            <td><p>10</p></td>
        </tr>
        <tr>
            <td><p>ASSISTIR AO VÍDEO E RESPONDER AO QUIZ<br>
                (GRUPO – toda Equipe cadastrada da<br>
                Revenda)*</p></td>
            <td><p>&nbsp;</p>
                <p>15</p></td>
            <td><p>&nbsp;</p>
                <p>15</p></td>
            <td><p>&nbsp;</p>
                <p>15</p></td>
            <td><p>&nbsp;</p>
                <p>15</p></td>
            <td><p>&nbsp;</p>
                <p>15</p></td>
        </tr>
        <tr>
            <td><p>SUBTOTAL**</p></td>
            <td><p>35</p></td>
            <td><p>35</p></td>
            <td><p>35</p></td>
            <td><p>35</p></td>
            <td><p>35</p></td>
        </tr>
    </table>

    <p>*	Caso todos os funcionários da Revenda assistam e respondam o quiz (mesmo que não acertem).<br>
        ** Cada funcionário cadastrado fará o máximo de 35 pontos ao mês.</p>
    <p>8.2.1.	      Serão pontuados os funcionários da Revenda que cumprirem o proposto até o último dia do mês correspondente. Serão considerados todos os funcionários cadastrados até o final do mês avaliado.</p>
    <p>8.2.2.	Ao final de cada mês, será calculado o total de pontos obtidos por todos os funcionários cadastrados, dividido pelo máximo de pontos que poderia ser feito por todo o time. Este resultado será o percentual de participação da Equipe da Revenda que formará o ranking de engajamento.</p>
    <p>8.2.3.	Baseado nesta métrica, serão premiadas dentro de cada uma das 4 regiões as 5 (cinco) Revendas que possuírem o maior percentual de participação, sendo uma por Faixa, totalizando 20 Revendas, que terão seu time contemplado, com o prêmio relativo ao período da contemplação descrito no quadro o item 9.2.4. do presente Regulamento.</p>
    <p>8.2.4.	Fica estabelecido que cada um dos funcionários da Revenda cadastrado no Programa receberá a seguinte premiação, de acordo com o mês de contemplação:</p>

    <table border="0" cellspacing="0" cellpadding="0">
        <tr>
            <td colspan="5"><p align="center"><strong>PREMIAÇÃO TIME PREPARADO –    EQUIPE DA REVENDA</strong></p></td>
        </tr>
        <tr>
            <td><p align="center"><strong>PERÍODO</strong></p></td>
            <td><p align="center"><strong>Faixa</strong></p></td>
            <td><p align="center"><strong>Colocação</strong></p></td>
            <td><p align="center"><strong>GANHADORES</strong></p></td>
            <td><p align="center"><strong>PRÊMIO </strong></p></td>
        </tr>
        <tr>
            <td><p align="center">OUT à NOV/2022</p></td>
            <td><p align="center">&nbsp;</p>
                <p align="center">1 a    5</p></td>
            <td><p align="center">&nbsp;</p>
                <p align="center">1º lugar</p></td>
            <td><p align="center">5    por região,<br>
                sendo 1 de cada Faixa</p></td>
            <td><p align="center">Crédito de R$ 200,00 na carteira    digital do Pic Pay, por   funcionário cadastrado e ativo.</p></td>
        </tr>
        <tr>
            <td><p align="center">DEZ à FEV/2023</p></td>
            <td><p align="center">&nbsp;</p>
                <p align="center">1 a    5</p></td>
            <td><p align="center">&nbsp;</p>
                <p align="center">1º lugar</p></td>
            <td><p align="center">5    por região,<br>
                sendo 1 de cada Faixa</p></td>
            <td><p align="center">Crédito de R$ 300,00 na carteira    digital do Pic Pay  por    funcionário cadastrado e ativo.</p></td>
        </tr>
    </table>


    <p>-	Carteira digital : para receber o crédito o funcionário precisa baixar o app  e realizar o cadastro com o CPF informado no site do Programa. Para recebimento do prêmio, é de responsabilidade do ganhador baixar o aplicativo , realizar o cadastro e ativar a conta. Para criação da conta , é necessário seguir o passo a passo descrito no site https://meajuda..com/hc/pt-br/articles/360046963132-O-que-eu-preciso-para-criar-a-minha-conta.</p>
    <p>8.2.5.	Ao final do período de participação compreendido entre outubro/2022 a fevereiro/2023, será calculado o total de pontos obtido por todos os funcionários cadastrados, dividido pelo máximo de pontos que poderia ser feito por todo o time, considerando o período total desta Fase. Este resultado  será considerado o percentual de participação da Equipe da Revenda.</p>
    <p>8.2.6.	Baseado nesta métrica, serão premiadas em cada uma das Regiões as 5 (cinco) Revendas que possuírem o maior percentual de participação, sendo uma por Faixa, totalizando 20 Revendas, que receberão a seguinte premiação:</p>
    <table border="0" cellspacing="0" cellpadding="0">
        <tr>
            <td colspan="5"><p align="center"><strong>PREMIAÇÃO TIME PREPARADO - REVENDEDOR</strong></p></td>
        </tr>
        <tr>
            <td><p align="center"><strong>PERÍODO</strong></p></td>
            <td><p align="center"><strong>Faixa</strong></p></td>
            <td><p align="center"><strong>Colocação</strong></p></td>
            <td><p align="center"><strong>GANHADORES</strong></p></td>
            <td><p align="center"><strong>PRÊMIO</strong></p></td>
        </tr>
        <tr>
            <td><p align="center">OUT/2022<br>
                a FEV/2023</p></td>
            <td><p align="center">1 a 5</p></td>
            <td><p align="center">1º lugar</p></td>
            <td><p align="center">5 por região, sendo 1  de cada Faixa.</p></td>
            <td><p align="center">KIT     Uniforme + Crédito    de  R$ 500,00 na carteira digital do    Pic Pay *</p></td>
        </tr>
    </table>
    <p>-	*Crédito na carteira digital : Para receber o crédito o Revendedor precisa baixar o app  e realizar o cadastro com o CPF informado no site do Programa. Será enviado um e-mail ao contemplado informando o CPF e nome cadastrado para checar se trata-se do responsável pela Revenda que receberá o crédito. Caso não haja resposta de alteração em 5 dias úteis, seguiremos com as tratativas para contemplar o CPF cadastrado.</p>
    <p>- KIT uniforme: composto por boné, camiseta e calça modelo entregador, todos com nova marca. A quantidade de Kits de uniformes será entregue conforme a Faixa da Revenda, de acordo com tabela abaixo:</p>
    <table border="0" cellspacing="0" cellpadding="0">
        <tr>
            <td colspan="2"><p align="center"><strong>KITS UNIFORMES</strong></p></td>
        </tr>
        <tr>
            <td><p align="center">FAIXA 1</p></td>
            <td><p align="center">3 KITS</p></td>
        </tr>
        <tr>
            <td><p align="center">FAIXA 2</p></td>
            <td><p align="center">6 KITS</p></td>
        </tr>
        <tr>
            <td><p align="center">FAIXA 3</p></td>
            <td><p align="center">9 KITS</p></td>
        </tr>
        <tr>
            <td><p align="center">FAIXA 4</p></td>
            <td><p align="center">12 KITS</p></td>
        </tr>
        <tr>
            <td><p align="center">FAIXA 5</p></td>
            <td><p align="center">24 KITS</p></td>
        </tr>
    </table>
    <p>8.2.6.1.	Em caso de empate na mecânica extra Time Preparado, serão adotados os seguintes critérios de desempate:</p>
    <p>1º critério: maior acerto individual no quiz<br>
        2º critério: maior acerto do quiz em grupo<br>
        3º critério: a maior Variação de Vendas (ΣΔV)), sem o limite de 110% (cento e dez por cento).</p>
    <h2>9.	PADRONIZAÇÃO DA REVENDA (MECÂNICA EXTRA)</h2>
    <p>9.1. As Revendas devem comprovar a padronização da sua fachada através de fotos que deverão ser inseridas no site www.desafioliquigas.com.br até dia 12/02/2022. A fachada precisa estar em acordo com o Manual de Padronização vigente, que será disponibilizado no site da campanha na área de Downloads. Todos os itens não podem conter a antiga marca da Design Sprint, seja com endosso BR Petrobras e/ou com a grafia antiga.<br>
        <br>
        9.2.	Cupons extras: as Revendas que comprovarem a padronização da fachada + frota (desde que com nova padronização e sem itens da marca antiga) ganharão um cupom adicional. Outro acelerador que dará um cupom extra para as revendas é o prazo de inclusão: aquelas que incluirem as fotos até 11/12/2022 receberão um cupom extra. </p>
    <p>9.3.	A Design Sprintrealizará a avaliação das fotos por meio de um auditor e caso aprovada, conforme Manual de Padronização, a Revenda fará jus aos cupons, conforme acima.</p>
    <p>9.4.	Forma de Sorteio: A Realizadora indicará, em divulgação às Revendas participantes, a forma de sorteio e a data de sua realização.</p>
    <p>9.5.	Forma de pagamento da premiação: Crédito na carteira do cliente.</p>
    <p>9.6.	Todas as Revendas que possuem fachada e que comprovarem a padronização poderão concorrer ao sorteio, independentemente de colocação. O valor da premiação será definido de acordo com a divisão por Faixa conforme volume de compras de GLP, de acordo com a tabela abaixo:</p>
    <table border="0" cellspacing="0" cellpadding="0">
        <tr>
            <td><p align="center"><strong>FAIXA</strong></p></td>
            <td><p align="center"><strong>PRÊMIO</strong></p></td>
        </tr>
        <tr>
            <td><p align="center">FAIXA 1</p></td>
            <td><p align="center">R$ 2.000,00</p></td>
        </tr>
        <tr>
            <td><p align="center">FAIXA 2</p></td>
            <td><p align="center">R$ 4.000,00</p></td>
        </tr>
        <tr>
            <td><p align="center">FAIXA 3</p></td>
            <td><p align="center">R$ 6.000,00</p></td>
        </tr>
        <tr>
            <td><p align="center">FAIXA 4</p></td>
            <td><p align="center">R$ 8.000,00</p></td>
        </tr>
        <tr>
            <td><p align="center">FAIXA 5</p></td>
            <td><p align="center">R$ 10.000,00</p></td>
        </tr>
    </table>

    <p>9.7.	Serão contempladas 20 (vinte) revendas no total, sendo 1 (uma) Revenda para cada Faixa e cada Região definidas no presente Regulamento.</p>
    <p>9.8.	As revendas sem fachada (como postos de combustíveis e supermercados), não participam do sorteio de padronização.</p>
    <h2>10.	ENTREGA DOS PRÊMIOS</h2>
    <p>10.1.	Carga de Gás: as Revendas vencedoras receberão em até 180 dias o valor equivalente à carga de GLP por meio de crédito na carteira de títulos, conforme tabela de valores por região e por faixa.</p>
    <p>10.1.1.	Fica estabelecido que caso a Revenda possua pendências (títulos, fundo de marketing, juros e demais encargos ou outros em aberto) estes poderão ser abatidos automaticamente, até o limite do valor da premiação. Caso o valor total do prêmio seja superior ao da primeira compra, é possível usar o saldo posteriormente, até que o valor total seja esgotado.</p>
    <p>10.1.2.	A Revenda não poderá transferir seu crédito para outra Revenda que não seja uma filial ou Revenda filha, nem poderá solicitar este valor em dinheiro em espécie.</p>
    <p>10.1.3.	Caso o Revendedor mude de bandeira ou vire independente, ou ainda, solicite o cancelamento do Fundo de Marketing durante o programa e/ou durante a fase de premiação, o mesmo perde   o direito ao prêmio seja ele integral ou parcial, devendo esta ser aplicada ao próximo da lista do ranking.</p>
    <p>10.1.4.	O valor do crédito será calculado proporcionalmente ao período de contribuição do Revendedor ao Fundo de Marketing. A proporcionalidade é calculada com base nos 12 meses anterior ao início do Programa. Por exemplo, se o Programa começa em outubro de 2023 e a Revenda entrou no início de abril de 2023,    a Revenda possuirá 50% de participação no Programa (6 meses de contribuição).</p>
    <p>10.2.	KIT Desafio: Fica estabelecido que o prazo para entrega da premiação é de até 180 dias após a divulgação dos   resultados.</p>
    <p>10.2.1.	Não será possível trocar os produtos que compoem o KIT Desafio.</p>
    <p>10.2.2.	O KIT Desafio é composto pelos seguintes produtos: imãs, panos de pratos,  aventais e sacolas, sendo que as unidades a serem recebidas serão definidas conforme a Faixa da Revenda, seguindo o disposto no item 7.1.3 do presente Regulamento e a tabela abaixo.</p>
    <table border="0" cellspacing="0" cellpadding="0" align="left">
        <tr>
            <td colspan="9"><p align="center"><strong>RANKING FINAL - KIT DESAFIO REVENDAS</strong></p></td>
        </tr>
        <tr>
            <td><p>&nbsp;</p></td>
            <td><p align="center">FAIXA 1</p></td>
            <td><p align="center">FAIXA 2</p></td>
            <td><p align="center">FAIXA 3</p></td>
            <td><p align="center">FAIXA 4</p></td>
            <td><p align="center">FAIXA 5</p></td>
            <td><p align="center">REGIÕES</p></td>
            <td><p align="center">PREMIADOS POR FAIXA</p></td>
            <td><p align="center">QTD PRÊMIOS</p></td>
        </tr>
        <tr>
            <td><p align="center">IMÃS</p></td>
            <td><p align="center">300</p></td>
            <td><p align="center">1.000</p></td>
            <td><p align="center">2.000</p></td>
            <td><p align="center">6.000</p></td>
            <td><p align="center">12.000</p></td>
            <td><p align="center">4</p></td>
            <td><p align="center">1</p></td>
            <td><p align="center">85.200</p></td>
        </tr>
        <tr>
            <td><p align="center">PANOS DE PRATO</p></td>
            <td><p align="center">100</p></td>
            <td><p align="center">400</p></td>
            <td><p align="center">800</p></td>
            <td><p align="center">1.200</p></td>
            <td><p align="center">2.500</p></td>
            <td><p align="center">4</p></td>
            <td><p align="center">1</p></td>
            <td><p align="center">20.000</p></td>
        </tr>
        <tr>
            <td><p align="center">AVENTAL</p></td>
            <td><p align="center">50</p></td>
            <td><p align="center">100</p></td>
            <td><p align="center">150</p></td>
            <td><p align="center">300</p></td>
            <td><p align="center">600</p></td>
            <td><p align="center">4</p></td>
            <td><p align="center">1</p></td>
            <td><p align="center">4.800</p></td>
        </tr>
        <tr>
            <td><p align="center">SACOLA</p></td>
            <td><p align="center">50</p></td>
            <td><p align="center">100</p></td>
            <td><p align="center">150</p></td>
            <td><p align="center">300</p></td>
            <td><p align="center">600</p></td>
            <td><p align="center">4</p></td>
            <td><p align="center">1</p></td>
            <td><p align="center">4.800</p></td>
        </tr>
    </table>
    <p>&nbsp;</p>
    <p>10.2.3.	Alguns itens terão personalização com o nome e telefone da Revenda, sendo assim, é de extrema importância que os dados de cadastro do site estejam atualizados. Após o resultado do ranking, a empresa Realizadora fará uma confirmação de todos os dados da Revenda, e utilizará estas informações para a entrega da premiação, sendo assim, não poderão ser alteradas ou contestadas posteriormente. Após ciência de que é uma ganhadora ou divulgação do resultado, a Revenda terá 15 (quinze) dias para informar os dados da Revenda para a confecção dos brindes, caso contrário, os itens personalizados serão produzidos sem os dados da Revenda, contendo somente o logotipo da Design Sprinte, no caso do ímã, o número de 0800 da central de atendimento Design Sprintao consumidor. </p>
    <p>10.3.	Crédito na carteira digital Pic Pay: o pagamento da premiação para a Equipe da Revenda e Top 500 Entregadores será feito apenas via crédito . O prazo para recebimento da premiação é até 60 (sessenta) dias corridos após divulgação dos resultados de cada mecânica, desde que o ganhador da Equipe tenha feito o cadastro no  e esteja apto ao recebimento.</p>
    <p>10.3.1.	Para receber o prêmio, é necessário fazer o download do app da carteira digital –  - no smartphone. Após fazer o download, clique em “Criar minha conta no !”. Passo 2. Para fazer o cadastro no aplicativo insira seu nome completo, número do celular para receber o código de confirmação via SMS, e-mail, senha para o perfil, CPF (o mesmo cadastrado no site do Programa) e data de nascimento.</p>
    <p>10.3.2.	Em caso de não recebimento do crédito, o ganhador deve apresentar contestação no prazo de 30 (trinta) dias corridos contados do fim do prazo do pagamento pela Realizadora.</p>
    <p>10.4.	KIT Uniformes: Os tamanhos dos uniformes serão pré-definidos e entregues em até 180 dias conforme tabela abaixo:</p>
    <table border="0" cellspacing="0" cellpadding="0">
        <tr>
            <td><p align="center"><strong>FAIXAS</strong></p></td>
            <td colspan="5"><p align="center"><strong>GRADE DE UNIFORMES</strong></p></td>
        </tr>
        <tr>
            <td><p align="center">&nbsp;</p></td>
            <td><p align="center"><strong>M</strong></p></td>
            <td><p align="center"><strong>G</strong></p></td>
            <td><p align="center"><strong>GG</strong></p></td>
            <td><p align="center"><strong>XG</strong></p></td>
            <td><p align="center"><strong>TOTAL</strong></p></td>
        </tr>
        <tr>
            <td><p align="center"><strong>FAIXA 1</strong></p></td>
            <td><p align="center">-</p></td>
            <td><p align="center">2</p></td>
            <td><p align="center">1</p></td>
            <td><p align="center">-</p></td>
            <td><p align="center"><strong>3</strong></p></td>
        </tr>
        <tr>
            <td><p align="center"><strong>FAIXA 2</strong></p></td>
            <td><p align="center">2</p></td>
            <td><p align="center">2</p></td>
            <td><p align="center">1</p></td>
            <td><p align="center">1</p></td>
            <td><p align="center"><strong>6</strong></p></td>
        </tr>
        <tr>
            <td><p align="center"><strong>FAIXA 3</strong></p></td>
            <td><p align="center">3</p></td>
            <td><p align="center">3</p></td>
            <td><p align="center">2</p></td>
            <td><p align="center">1</p></td>
            <td><p align="center"><strong>9</strong></p></td>
        </tr>
        <tr>
            <td><p align="center"><strong>FAIXA 4</strong></p></td>
            <td><p align="center">3</p></td>
            <td><p align="center">6</p></td>
            <td><p align="center">2</p></td>
            <td><p align="center">1</p></td>
            <td><p align="center"><strong>12</strong></p></td>
        </tr>
        <tr>
            <td><p align="center"><strong>FAIXA 5</strong></p></td>
            <td><p align="center">6</p></td>
            <td><p align="center">12</p></td>
            <td><p align="center">4</p></td>
            <td><p align="center">2</p></td>
            <td><p align="center"><strong>24</strong></p></td>
        </tr>
    </table>

    <p>10.4.1.	 Cada uniforme contido no KIT Uniformes contém 1 camisa manga curta, 1 calça comprida, 1 boné – todos sem a personalização do telefone da Revenda.</p>
    <p>10.4.2.	 Os materiais serão enviados no endereço da Revenda, conforme endereço cadastrado do revendedor junto à Design Sprint.</p>
    <p>10.5.	 Troféu: Os troféus serão entregues no prazo de até 180 dias corridos. A entrega será realizada pelo consultor comercial da Revenda.</p>
    <h2>11. ADESÃO À PLATAFORMA ACELERAÍ E MULTIPLICA (MECÂNICA EXTRA DE PREMIAÇÃO FORA DA PLATAFORMA)</h2>
    <p>11.1.	 A mecânica extra de adesão à PLATAFORMA ACELERAÍ E MULTIPLICA não é atrelada ao  - Design Sprint. A Revenda que realizar a adesão na plataforma Aceleraí entre 18/08/2022 e 20/10/2022 e também tiver feito algum investimento em impulsionamento (Multiplica), concorrerá a um ingressos VIP para participar da Stock Car. O prazo para adesão e investimento será até dia 20/10/2022. </p>
    <p>11.2.	 Será sorteado 1 (um) ingresso por região, totalizando 4 (quatro) ganhadores, sem direito a acompanhante. A Realizadora indicará, em divulgação às revendas participantes, a forma de sorteio e a data de sua realização.</p>
    <p>11.3.	Após ser notificado como vencedor do ingresso, o sócio/proprietário da Revenda terá 3 (três) dias úteis para informar quem será a pessoa que irá ao evento e seus dados. Caso a Revenda decline ou não responda dentro do período, passaremos para o suplente seguinte no sorteio.</p>
    <p>11.4.	 Convite Stock Car: O convite Stock Car será entregue na entrada do evento. A data da etapa Stock Car será definida pela Realizadora após apuração dos ganhadores. </p>
    <p>11.4.1.	 Fica estabelecido que as datas podem ser alteradas pela organizadora ou por motivos de força maior, e o horário será definido e informado previamente pela Realizadora. Os ganhadores receberão 1 (um) convite para assistir presencialmente a uma etapa da Stock Car + 1 (um) KIT composto por 1 (boné), 1 (um) camiseta e 1 (uma sacochila).</p>
    <p>11.4.2.	O convite contempla: convite para entrada no evento e um cartão de R$ 500,00 (quinhentos reais) para gastos com transporte e/ou alimentação até o local do evento. Ficam os ganhadores cientes de a etapa será definida e divulgada aos ganhadores através dos canais de comunicação da Campanha.</p>
    <h2>12. MECÂNICA EXTRA - TOP 500 ENTREGADORES (ATRELADA À PROMOÇÃO “COPA NA COZINHA – PROMOÇÃO Design Sprint”, autorizada pelo Certificado de Autorização SEAE/ME Nº 05.021318/2022. </h2>
    <p>12.1. A Promoção “COPA NA COZINHA – PROMOÇÃO Design Sprint” não é de nenhuma forma atrelada ao  - Design Sprint. No período de agosto/2022 a novembro/2022 os entregadores que tiverem mais CPFs únicos cadastrados na “Promoção Design SprintCopa na Cozinha”, serão contemplados.</p>
    <p>12.2. Na mecânica da Promoção, os consumidores participantes da Promoção comercial autorizada deverão cadastrar o código constante no cupom promocional recebido no ato da compra do botijão e o “código do entregador”, para saber na hora se foram ou não contemplados com a premiação instantânea descrita no Regulamento, lembrando que o código do entregador não é obrigatorio para finalização do cadastro do consumidor. </p>
    <p>12.3. Fica estabelecido que ao final do período de participação, que se inicia em 01/08/2022 e termina em 30/11/2022 às 23h59, será feito um ranking, sendo contemplados os 500 (quinhentos) entregadores nível Brasil que apresentarem o maior número de CPFs únicos cadastrados na Promoção Design SprintCopa na Cozinha, com seu código identificador durante o período da promoção.</p>
    <p>12.4. Os 500 (quinhentos) entregadores que tiverem o maior número de CPFs únicos cadastrados em seu código do entregador na Promoção Design SprintCopa na Cozinha, serão contemplados com 01 (um) crédito na conta digital  no valor de R$ 500,00 (quinhentos reais), cada. O resultado será divulgado até dia 31 de dezembro no site do  - Design Sprint. O prazo de pagamento será em até 60 (sessenta) dias após a divulgação dos vencedores.</p>
    <p>12.5. Em caso de empate entre os últimos 500 entregadores finalistas, fica como critério de desempate a maior quantidade de cupons cadastrado em seu código do entregador. </p>
    <p>12.6. Cada entregador ou frentista cadastrado na área exclusiva do revendedor no site www.promocaoliquigas.com.br, receberá um código único em numeral. Este número identificará cada entregador e deverá ser escrito do lado de fora do cupom que entregar, junto com a venda de um botijão, durante o período de realização da Promoção Design Sprint– Copa na Cozinha, a qual é destinada aos consumidores. </p>
    <p>12.7. Fica estabelecido que será desclassificada a Revenda que concentrar cupons em um entregador, caso tenha mais entregadores, ou que realize qualquer fraude neste processo.</p>
    <p>12.8. Em caso de desligamento do funcionário até a data de pagamento do prêmio, o prêmo é repassado ao suplente. </p>
    <h2>13. DISPOSIÇÕES GERAIS</h2>
    <p>13.1.	 A participação no Programa é voluntária e implicará a aceitação total e irrestrita de todos os itens deste Regulamento.</p>
    <p>13.2.	Não terão validade as participações que não preencherem as condições básicas do Programa previstas neste Regulamento.</p>
    <p>13.3.	Os Revendedores contemplados deverão fornecer a documentação solicitada para a entrega do prêmio, bem como assinar um recibo de entrega de quitação do prêmio, além de passarem a ser os únicos e exclusivos responsáveis pela entrega da premiação aos seus funcionários. A assinatura deste documento dará quitação geral e ampla do direito de reclamá-lo.</p>
    <p>13.4.	As Revendas que tiverem ação judicial junto à Design Sprintperdem automaticamente o direito ao prêmio.</p>
    <p>13.5.	Revendas que deixarem de ser Revendedoras Design Sprintestarão automaticamente desclassificadas, mesmo que o fato ocorra após a divulgação do ranking final. Consequentemente, seus funcionários também perderão o direito à premiação.</p>
    <p>13.6.	Em nenhuma hipótese será permitido ao contemplado trocar a premiação pelo valor correspondente em dinheiro.</p>
    <p>13.7.	Os prêmios serão entregues livres e desembaraçados de qualquer ônus para o contemplado.</p>
    <p>13.8.	Na eventualidade do contemplado vir a falecer, o prêmio será entregue ao respectivo espólio, na pessoa do seu inventariante. Não havendo processo de inventário, será entregue aos herdeiros do contemplado, desde que devidamente comprovada esta condição.</p>
    <p>13.9.	O contemplado no Programa tem o prazo de até 180 (cento e oitenta) dias corridos após a divulgação do resultado para requerer seu prêmio bem como para reportar eventuais problemas no recebimento e/ou sua utilização. Após esse prazo a Realizadora isenta-se de qualquer responsabilidade pelo mesmo.</p>
    <p>13.10.	Constatada ao final do Desafio, após a apuração dos pontos, a não vinculação da Revenda à Design Sprint, nos termos das Resoluções 51 e 709 da ANP (disponíveis em https://cpl.anp.gov.br/anp-cpl-web/public/simp/consulta-revendas/consulta.xhtml), a participante será automaticamente excluída do Programa e não poderá receber os prêmios. </p>
    <p>13.11.	A Realizadora não se responsabiliza pela autenticidade dos dados eventualmente fornecidos pelos participantes.</p>
    <p>13.12.	Os Revendedores, ou seus funcionários, que burlarem as regras e condições deste regulamento ou atentarem contra os objetivos do Programa, serão sumariamente desclassificados, sem prejuízo da aplicação de demais sanções no campo cível e penal.</p>
    <p>13.13.	Os contemplados autorizam a empresa Design Sprinta utilizar o seu nome, imagem e som de voz, gratuitamente, em caráter irrevogável, irretratável e universal, pelo prazo de 5 (cinco) anos, contados da data de encerramento da promoção, para divulgação da presente promoção, em todos os meios de comunicação, incluindo, mas sem se limitar a: televisão, jornal, revista, mídia exterior, internet, rede sociais, entre outros, não significando, implicando ou resultando em qualquer obrigação de divulgação nem de pagamento, concordando ainda, inclusive, em assinar eventuais recibos e instrumentos neste sentido e para tal efeito, sempre que solicitado pela Realizadora. </p>
    <p>13.14.	A Design Sprintpoderá, a qualquer tempo, verificar o cumprimento das regras pelos participantes.</p>
    <p>13.15.	Caso o ganhador tenha aderido ao Fundo de Marketing antes de março de 2023, não tendo efetuado o pagamento referente aos 12 (doze) meses  de contribuição (março de 2023 a fevereiro de 2023), o valor do crédito da carga de GLP será calculado proporcionalmente ao período de contribuição realizado pelo Revendedor.<br>
        Exemplo: caso a Revenda tenha feito adesão ao Fundo de Marketing na data de 31/08/2022, contribuindo por 6 meses (setembro de 2023 a março de 2023), irá receber 50% da premiação da carga de GLP.</p>
    <p>13.16.	Fica estabelecido que caso o Revendedor tenha contribuído parcialmente para o Fundo de Marketing, poderá através de boleto, fazer o pagamento da diferença e participar de maneira integral até o final do mês de dezembro de 2023. O participante reconhece e aceita expressamente que a Design Sprintnão poderá ser responsabilizada por qualquer dano ou prejuízo oriundo da participação neste Programa ou da    eventual aceitação do prêmio.</p>
    <p>13.17.	Os participantes são responsáveis perante os órgãos fiscalizadores a contabilizar as premiações eventualmente recebidas neste Programa, conforme a legislação vigente.</p>
    <p>13.18.	O presente Regulamento poderá ser alterado e/ou o Programa suspenso ou cancelado, sem aviso prévio, por motivo de força maior ou por qualquer outro motivo que esteja fora do controle da Design Sprint, e que comprometa a realização do Programa de forma a impedir ou modificar substancialmente a sua condução como originalmente planejado, não gerando qualquer direito aos participantes. Caso qualquer dos participantes discorde das alterações realizadas, este terá o direito de deixar de participar do Programa.</p>
    <p>13.19.	Quaisquer dúvidas, divergências ou situações não previstas neste Regulamento serão julgadas e decididas de forma soberana e irrecorrível pela Design Sprint.</p>
    <p>13.20.	A Design Sprintdisponibilizará o e-mail sac@designsprint.com.br para esclarecimentos de eventuais dúvidas que possam surgir no decorrer deste Programa.</p>
    <p>13.21.	A participação neste Programa implica na aceitação total e irrestrita de todos os itens deste Regulamento, que será disponibilizado aos participantes no site do Programa www.desafioliquigas.com.br. </p>
    <p>13.22.	O resultado final do Programa será divulgado até o dia 15/04/2023, no site do Programa www.desafioliquigas.com.br.</p>
    <p>13.23.	Todos os cadastros serão armazenados num banco de dados único do Programa.</p>
    <p>13.24.	Os dados e informações coletados estarão armazenados em ambiente seguro, observado o estado da técnica disponível, e somente poderão ser acessados por pessoas qualificadas e previamente autorizadas, em observância à legislação em vigor.</p>
    <p>13.25.	Ao participar deste Programa, os participantes estarão, automaticamente, reconhecendo e consentindo que haverá a coleta, armazenamento e outros tratamentos necessários dos seus dados pessoais e pessoais sensíveis, bem como o compartilhamento destes e de sua imagem com a empresa Realizadora e eventuais Parceiros para o cumprimento das obrigações decorrentes deste Programa, para fins de auditoria, para o cumprimento de obrigações legais e para o exercício regular de direitos da empresa Realizadora, durante a vigência deste Programa e posteriormente, pelos prazos exigidos por lei e de acordo com as regras de retenção de dados da empresa Realizadora.</p>
    <p>13.26.	A Realizadora e Parceiros prezam pela privacidade e proteção de dados das Pessoas Naturais contratantes dos serviços ligados ao presente Programa – que são titulares de dados maiores de 18 (dezoito) anos de idade, e utiliza os dados pessoais coletados somente para fins relacionados ao Programa. Ao participar do Programa, e realizar o cadastro com login e senha, o participante expressamente concorda com essa operação para os devidos fins.</p>
    <p>13.27.	A Realizadora e Parceiros deverão manter as bases de dados pelo prazo de 5 (cinco) anos após o término do Programa, de modo a atender eventuais questionamentos e evidenciar a lisura do procedimento do presente Programa. Após o referido período, a Realizadora excluirá do seu banco de dados as informações dos participantes que participaram do Programa, devendo manter em seu poder evidência dessa exclusão.</p>
    <p>13.28.	A Realizadora e Parceiros se obrigam com relação ao atendimento de quaisquer direitos de titular exercidos pelo participante perante si, em prazo adequado, pelo período de 5 (cinco) anos. </p>
    <p>13.29.	O participante fica ciente desde já que a participação no Programa implica no compartilhamento das suas informações como CPF e informações cadastrais com prestadores de serviços contratados pela Realizadora, para divulgação do resultado do Programa e eventual entrega do prêmio.</p>
    <p>13.30.	Os participantes autorizam a utilização de seus endereços físicos, eletrônicos, telefones e dados informados, com o propósito de formação e atualização de cadastro e divulgação do próprio Programa, nos limites do Código de Defesa do Consumidor e LGPD, sem nenhum ônus para a Realizadora.</p>
    <p>13.31.	O participante, caso queira, poderá solicitar à Realizadora qualquer tempo, através do e-mail  sac@designsprint.com.br, a exclusão de seus dados pessoais do banco de dados do Programa. Todavia, caso a solicitação ocorra durante o período de vigência do Programa, fica ciente de que a sua participação restará prejudicada, uma vez que os dados pessoais são necessários para garantir sua participação e isso implicará na sua desclassificação.</p>
    <p>13.32.	Os dados e informações coletados estarão armazenados em ambiente seguro, observado o estado da técnica disponível, e somente poderão ser acessados por pessoas qualificadas e previamente autorizadas, em observância à legislação em vigor.</p>
    <p>13.33.	A premiação não caracteriza salário indireto, comissionamento, vendas ou remuneração variável.</p>
    <p>13.34.	O presente Programa não importa na criação de qualquer vínculo trabalhista, societário, de parceria ou associativo entre os participantes e a Realizadora, preservando-se os vínculos previamente existentes.</p>
    <p>13.35.	Fica, desde já, eleito o foro central da Comarca de São Paulo, para solução de quaisquer questões referentes ao Regulamento do presente Programa.</p>
</ng-template>
