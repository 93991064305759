import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {NewGQL, News, NewsGQL, NewsPage} from "../../../generated/graphql";


declare var $: any;

@Component({
    selector: 'app-news-detail',
    templateUrl: './news-detail.component.html',
    styleUrls: ['./news-detail.component.scss']
})
export class NewsDetailComponent extends BaseComponent implements OnInit {
    news: News = new News();
    newsPage: NewsPage = new NewsPage();

    constructor(public router: Router,
                public router2: ActivatedRoute,
                public newGQL: NewGQL,
                public newsGQL: NewsGQL,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngOnInit(): void {
        this.router2.params.subscribe(url => {
            if (!this.isNullOrUndefined(url)) {
                this.newGQL.watch({id: url.id}).valueChanges.subscribe(({data}) => {
                    this.news = data.new as News;
                    this.newsGQL.watch({page: 1, type: 'news'}).valueChanges.subscribe(({data}) => {
                        this.newsPage = data.news as NewsPage;
                        setTimeout(() => {
                            $('#slick-news').slick({dots: true, arrows: false, slidesToShow: 1, variableWidth: true});
                        }, 200);
                    }, error => super.onError(error));
                }, error => super.onError(error));
            }
        });
    }
}
