<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg text-left">Cases</p>
        <div class="container">
            <h2 class="page_header_title">Cases</h2>
        </div>
    </div>
</div>
<div class="container container-hero">

    <div class="my-5 py-5" *ngIf="newsPage.news.length === 0" >
        <p class="text-center">Nenhum item encontrado.</p>
    </div>

    <div class="mt-5">
        <div class="news_border" *ngFor="let n of newsPage.news |
                paginate: {itemsPerPage: newsPage.pageSize,
                currentPage: newsPage.currentPage,
                totalItems: newsPage.totalCount }">
            <div class="row mb-3">
                <div class="col-sm-auto">
                    <img class="news_image" style="min-height: 150px"
                         src="{{this.getImageURL() + 'news/image/' +n.pathImage}}"
                         alt="image">
                </div>
                <div class="col-sm order-sm-first">
                    <div class="d-flex flex-column justify-content-between h-100">
                        <div>
                            <h3 class="news_title">{{n.title}}</h3>
                            <div class="html-block html-block-short" [innerHTML]="n.text"></div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between">
                            <p class="news_created mb-0">{{n.createdAt | date:'dd/MM/yyyy | HH:mm':'UTC-0'}}</p>
                            <a [routerLink]="'/cases-detalhe/' + n.id" class="d-flex align-items-center"
                               style="gap: 15px">
                                <p class="news_links mb-0">Leia Mais</p>
                                <svg-icon src="assets/svg/arrow.svg"></svg-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-right my-5">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>
</div>
