import { Component, OnInit } from '@angular/core';
declare var $ : any

@Component({
  selector: 'app-decide',
  templateUrl: './decide.component.html',
  styleUrls: ['./decide.component.scss']
})
export class DecideComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  handlerCollapse(id: string) {
    $('#' + id).collapse('toggle');
  }

}
