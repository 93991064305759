<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg">Sobre</p>
        <div class="container">
            <h2 class="page_header_title">Sobre</h2>
        </div>
    </div>
</div>
<div class="container">
    <div class="row no-gutters align-items-center mb-5">

        <div class="col-sm-6">
            <p class="page_subtitle mb-4">O que é  <br><span>  Design Sprint?</span> </p>

            <p class="page_text">Criado pelo designer Jake Knapp em 2010, o Design Sprint é o método usado no Google para testar e aplicar novas ideias em apenas cinco dias.</p>
        </div>
        <div class="col-sm-6">
            <img src="assets/images/about1.png" alt="">
        </div>
    </div>

</div>
<div class="container">
    <div class="row no-gutters align-items-center mb-5">


        <div class="col-sm-6">
            <img src="assets/images/about2.jpg" alt="">
        </div>
        <div class="col-sm-6">
            <p class="page_subtitle mb-4">Mas afinal, o que é  <br><span>  Design Sprint?</span> </p>

            <p class="page_text">
                O Design Sprint é um método criado em 2010 no Google pelo designer Jake Knapp. Seu principal objetivo é testar e aplicar novas ideias em apenas cinco dias por meio da prototipagem e do teste com consumidores reais. Tudo isso em um ambiente amigável, divertido e criativo.</p>
        </div>
    </div>

</div>

<div class="container">
    <div class="row no-gutters align-items-center mb-5">

        <div class="col-sm-6">
            <p class="page_subtitle mb-4">Para quem é o <br><span>  Design Sprint?</span> </p>

            <p class="page_text">
                Para sobreviver e crescer no mercado, as empresas devem inovar continuamente. Como o foco do Design Sprint é testar e aplicar novas ideias para resolver desafios de transformação digital, o método é ideal para equipes de projetos, inovação, gerenciamento e marketing. </p>
        </div>
        <div class="col-sm-6">
            <img src="assets/images/about3.png" alt="">
        </div>

    </div>

</div>


<div class="container">
    <div class="row no-gutters align-items-center mb-5">


        <div class="col-sm-6">
            <img src="assets/images/about4.png" alt="">
        </div>
        <div class="col-sm-6">
            <p class="page_subtitle mb-4">Como executar o <br><span>  Design Sprint?</span> </p>

            <p class="page_text">
                A ideia por trás do Design Sprint é, de fato, muito simples. Uma equipe multidisciplinar de até sete pessoas limpa o calendário por cinco dias e se dedica a aplicar o método, passando rapidamente dos desafios críticos do negócio às soluções testadas por usuários reais.</p>
        </div>

    </div>

</div>

<section class="page-padding how">
    <div class="row justify-content-center">
        <p class="page_subtitle text-center mb-4 text-white">Como são os dias do <br><span class="text-white">  Design Sprint</span> </p>
        <div class="row">
        <div class="col-md-4 card-about">
            <span class="card-about-title">Organizar</span>
            <img src="assets/images/aboutline1.png" alt="">
            <span class="text-white">
                Antes de iniciar vocês precisarão ter a equipe certa e o desafio certo. Também precisarão de tempo e espaço para conduzir um Design Sprint perfeito.
            </span>
            <div class="about-btn" [routerLink]="'/organizar'">
                Quero Saber Mais
            </div>
        </div>
        <div class="col-md-4 card-about">
            <span class="card-about-title">Mapear</span>
            <img src="assets/images/aboutline2.png" alt="">
            <span class="text-white">
                A segunda-feira do Design Sprint é uma série de conversas estruturadas para construir uma base – e um foco – para a semana. A estrutura permite que a equipe compartilhe o máximo de informações o mais rápido possível, evitando as habituais conversas sinuosas.
            </span>
            <div class="about-btn" [routerLink]="'/mapear'">
                Quero Saber Mais
            </div>
        </div>
        <div class="col-md-4 card-about">
            <span class="card-about-title">Esboçar</span>
            <img src="assets/images/aboutline3.png" alt="">
            <span class="text-white">
               A terça-feira do Design Sprint começa em busca de inspiração. Em vez de um típico brainstorm, cada membro da equipe esboçará suas próprias soluções detalhadas, seguindo um processo de quatro etapas que enfatiza o pensamento crítico ao invés da arte.
            </span>
            <div class="about-btn" [routerLink]="'/esbocar'">
                Quero Saber Mais
            </div>
        </div>
        <div class="col-md-4 card-about">
            <span class="card-about-title">Decidir</span>
            <img src="assets/images/aboutline4.png" alt="">
            <span class="text-white">
                Na quarta-feira do Design Sprint, vocês terão uma pilha de soluções. Agora, vocês deverão decidir quais desses esboços devem ser prototipados e testados.
            </span>
            <div class="about-btn" [routerLink]="'/decidir'">
                Quero Saber Mais
            </div>
        </div>
        <div class="col-md-4 card-about">
            <span class="card-about-title">Prototipar</span>
            <img src="assets/images/aboutline5.png" alt="">
            <span class="text-white">
                Na quarta-feira, vocês criaram um storyboard. Na quinta-feira, adotarão a filosofia do “finjam” para transformar esse storyboard em um protótipo realista.
            </span>
            <div class="about-btn"  [routerLink]="'/prototipar'">
                Quero Saber Mais
            </div>
        </div>
        <div class="col-md-4 card-about">
            <span class="card-about-title">Testar</span>
            <img src="assets/images/aboutline6.png" alt="">
            <span class="text-white">
                Na sexta-feira do Design Sprint, vocês mostrarão o protótipo para cinco clientes em cinco entrevistas individuais. Em vez de esperarem por um lançamento para obterem insights, vocês obterão as respostas que precisam imediatamente.
            </span>
            <div class="about-btn" [routerLink]="'/testar'">
                Quero Saber Mais
            </div>
        </div>
        </div>

    </div>
</section>

<section class="page-padding">
    <div class="row justify-content-center">
        <p class="page_subtitle text-center mb-4 ">Qual o impacto do <br><span>  Design Sprint</span> </p>
        <div class="row justify-content-center">
            <div class="col-md-3 d-flex flex-column align-items-center my-3">
                <img class="about-icon" src="assets/images/abouticon1.JPG" alt="">
                <span class="text-center">
                Fazendo mais progressos em cinco dias do que normalmente seria feito em meses.
                </span>

            </div>
            <div class="col-md-3 d-flex flex-column  align-items-center my-3">
                <img class="about-icon" src="assets/images/abouticon2.JPG" alt="">
                <span class="text-center">
                Economizar muito dinheiro através da prototipagem para saber se a ideia dará certo ou não.
            </span>

            </div>
            <div class="col-md-3 d-flex flex-column  align-items-center my-3">
                <img class="about-icon" src="assets/images/abouticon3.JPG" alt="">
                <span class="text-center">
                Eliminar a necessidade de reuniões intermináveis que não chegam a lugar nenhum.
            </span>

            </div>

        </div>

    </div>
</section>

<section class="page-padding">
    <div class="row justify-content-center">
        <p class="page_subtitle text-center mb-4 ">O que as pessoas acham sobre o <br><span>  Design Sprint?</span> </p>
        <div class="row justify-content-center">
            <div class="col-md-3 d-flex flex-column align-items-center my-3">
                <img class="about-user" src="assets/images/aboutuser1.png" alt="">
                <span class="text-center">
               "O Design Sprint é uma abordagem eficiente e prática para resolver seus desafios de design de produtos, serviços e experiências.”
                </span>
                <b>Tim Brown</b>
                <small>Ceo da IDEO</small>
                <a target="_blank" class="text-primary" href="https://twitter.com/tceb62">@tceb62</a>

            </div>

            <div class="col-md-3 d-flex flex-column align-items-center my-3">
                <img class="about-user" src="assets/images/aboutuser2.png" alt="">
                <span class="text-center">
              "O Design Sprint é uma fórmula transformadora para testar ideias que funciona se você está em uma startup ou em uma grande organização.”
                </span>
                <b>Tim Brown</b>
                <small>Autor de The Lean Startup</small>
                <a target="_blank" class="text-primary" href="https://twitter.com/ericries">@ericries</a>

            </div>
            <div class="col-md-3 d-flex flex-column align-items-center my-3">
                <img class="about-user" src="assets/images/aboutuser3.png" alt="">
                <span class="text-center">
              "A genialidade do Sprint é o detalhamento passo a passo do que é necessário para fazer o trabalho que importa com velocidade e urgência."
                </span>
                <b>Beth Comstock</b>
                <small>Vice-presidente da GE</small>
                <a target="_blank" class="text-primary" href="https://twitter.com/bethcomstock">@bethcomstock</a>

            </div>

        </div>

    </div>
</section>


