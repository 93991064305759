<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg text-left">&nbsp;</p>
        <div class="container">
            <h2 class="page_header_title">{{news.title}}</h2>
        </div>
    </div>
</div>
<div class="hero-banner">
    <img [src]="getImageURL()+ 'news/image/'+news.pathImage" class="hero-banner-thumb" alt="{{news.title}}"/>
</div>
<div class="container container-hero mt-5">
    <div class="row mb-5" style="row-gap: 20px">
        <div class="col-12 col-md-6 position-relative">
            <a href="javascript:history.back(-1); void('');" class="btn btn-outline-secondary back_button">
                <div class="d-flex align-items-center" style="gap: 20px">
                    <svg-icon src="assets/svg/arrow.svg"></svg-icon>
                    <p class="mb-0">Voltar</p>
                </div>
            </a>
        </div>
        <div class="col-12 col-md-6 position-relative text-right">
            <!--<p class="main_news_text"><small>Publicada em {{news.createdAt | date: 'dd/MM/yyyy'}}</small></p>-->
        </div>
    </div>
    <div class="row">
        <div class="col">
            <!--<div class="mb-4">
                <img [src]="getImageURL()+ 'news/image/'+news.pathImage" class="img-fluid w-100" alt="{{news.title}}"/>
            </div>-->
            <!--<h2 class="text-uppercase text-primary">{{news.title}}</h2>-->
            <div class="main_news_text" [innerHTML]="news.text"></div>
        </div>
        <div class="d-none d-lg-block col-2">&nbsp;</div>
    </div>
    <div class="row">
        <div class="col">
            <div class="d-flex align-items-center mb-5" style="gap: 10px">
                <span class="news-detail_date">{{news.createdAt|date:'dd/MM/yyyy':'UTC-0'}}</span>
                <div class="divider">&nbsp;</div>
            </div>
        </div>
    </div>

</div>


