<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg mb-0">FALE CONOSCO</p>
        <div class="container">
            <h2 class="page_header_title">FALE <span>CONOSCO</span></h2>
        </div>
    </div>
</div>
<div class="col-12 no-gutters background-primary">
    <div class="container contact_bg">
        <form autocomplete="off" class="row justify-content-between d-flex" style="row-gap: 20px">
            <div class="col-lg-4 align-items-center d-flex bg-ball">
                <div class="row no-gutters align-items-center contact_bg-verify d-flex">
                    <p class="col contact_text">Verifique se sua dúvida está respondida na área <b>Perguntas Frequentes.</b></p>
                    <div class="col-sm-8 d-flex flex-column align-items-center">
                        <img style="max-width: 100%" src="assets/images/logo-header.png" alt="image">
                        <a class="btn-faq btn btn-block btn-secondary" routerLink="/faq"> Perguntas Frequentes </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 d-flex justify-content-center flex-column">
                <p class="text-white mt_b">Se preferir, entre em contato conosco para esclarecer dúvidas, fazer sugestões
                    ou reclamações relacionadas à
                    campanha.</p>
                <p class="text-white mt_b">Basta preencher o formulário abaixo ou enviar sua mensagem pelo WhatsApp clicando no
                    ícone.</p>
            </div>
            <div class="col-lg-4 position-relative">
                <div class="contact_card">

                    <div class="input-material ">
                        <input [(ngModel)]="createcontact.name" type="text" class="form-control" id="name"
                               maxlength="100" name="name" required/>
                        <label for="name">Nome Completo*</label>
                    </div>
                    <div class="input-material">
                        <input [(ngModel)]="createcontact.phone" type="text" class="form-control" id="phone"
                               mask="(00) 0000-0000||(00) 0 0000-0000"
                               maxlength="100" name="phone" required/>
                        <label for="phone">Telefone com DDD*</label>
                    </div>
                    <div class="input-material">
                        <input [(ngModel)]="createcontact.email" type="text" class="form-control" id="email"
                               maxlength="100" name="email" required/>
                        <label for="email">Email*</label>
                    </div>

                    <div class="input-material select">
                        <select [(ngModel)]="createcontact.subjectId" class="form-control" id="subject" name="subject"
                                required>
                            <option *ngFor="let o of subjectList" [ngValue]="o.id">{{o.name}}</option>
                        </select>
                        <label for="subject">Assunto*</label>
                    </div>

                    <div class="input-material">
                  <textarea [(ngModel)]="createcontact.message" type="text" class="form-control" id="message"
                            maxlength="100" rows="3" name="message" required></textarea>
                        <label for="message">Mensagem*</label>
                    </div>
                    <a (click)="actionContact()" href="javascript:void(0)"
                       class="my-3 btn btn-secondary btn-icon-left btn-block">
                        Enviar
                    </a>

                </div>
            </div>
        </form>
    </div>
</div>

