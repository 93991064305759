import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {NewGQL, News, NewsGQL, NewsPage} from "../../../generated/graphql";
import {UserService} from "../../service/user.service";


declare var $: any;

@Component({
    selector: 'app-articles-detail',
    templateUrl: './news-detail.component.html',
    styleUrls: ['./news-detail.component.scss']
})
export class ArticlesDetailComponent extends BaseComponent implements OnInit {
    news: News = new News();
    newsPage: NewsPage = new NewsPage();
    flagMember = null;

    constructor(public router: Router,
                public router2: ActivatedRoute,
                public newGQL: NewGQL,
                public newsGQL: NewsGQL,
                public translate: TranslateService,
                public userService: UserService) {
        super(router, translate);
    }

    ngOnInit(): void {
        this.flagMember = this.userService.getStatusAuthenticated();

        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    this.flagMember = this.userService.getStatusAuthenticated();
                    // @ts-ignore
                    this.getArticle(this.router2.params._value);
                }
            }, error: err => super.onError(err)
        });
        if (!this.flagMember) { return; }

        this.router2.params.subscribe(url => {
            if (!this.isNullOrUndefined(url)) {
                this.getArticle(url);
            }
        });
    }

    getArticle(url: any) {
        this.newGQL.watch({id: url.id}).valueChanges.subscribe(({data}) => {
            this.news = data.new as News;
            this.newsGQL.watch({page: 1, type: 'article'}).valueChanges.subscribe(({data}) => {
                this.newsPage = data.news as NewsPage;
                setTimeout(() => {
                    $('#slick-news').slick({dots: true, arrows: false, slidesToShow: 1, variableWidth: true});
                }, 200);
            }, error => super.onError(error));
        }, error => super.onError(error));
    }
}
