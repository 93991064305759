import { Component, OnInit } from '@angular/core';

declare var $ : any;

@Component({
  selector: 'app-prototype',
  templateUrl: './prototype.component.html',
  styleUrls: ['./prototype.component.scss']
})
export class PrototypeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  handlerCollapse(id: string) {
    $('#' + id).collapse('toggle');
  }

}
