import {NgModule} from "@angular/core";
import {APOLLO_OPTIONS} from "apollo-angular";
import {ApolloClientOptions, DefaultOptions, InMemoryCache} from "@apollo/client/core";
import {HttpLink} from "apollo-angular/http";
import {onError} from "@apollo/client/link/error";
import {environment} from "../environments/environment";
import Swal from "sweetalert2";


const uri = environment.base_graphql; // <-- add the URL of the GraphQL server here

const error = onError(({graphQLErrors, networkError}) => { // need help on linking this with graphql module
    console.log('networkError');
    const e: any = graphQLErrors;
    // console.log(e);
    // console.log(e[0]);
    // debugger;
    if (e) {
        if (e[0]?.extensions?.response?.statusCode === 401 || e[0]?.extensions?.exception?.status === 401) {
            // window.location.href = '/login';
        } else if (e[0]?.message) {
            Swal.fire({
                title: 'Atenção',
                html: e[0].message,
                icon: 'warning',
                confirmButtonColor: '#032E58',
            });
        } else if (e[0]?.errors) {
            Swal.fire({
                title: 'Atenção',
                html: e[0].erros[0].message,
                icon: 'warning',
                confirmButtonColor: '#032E58',
            });
        }
        e.map(({message, locations, path}) => {
                Swal.fire({
                    title: 'Atenção',
                    html: message,
                    icon: 'warning',
                    confirmButtonColor: '#032E58',
                });
                // console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
            },
        );
    }
    if (networkError) {
        const e: any = networkError;
        if (e.status === 401) {
            // @ts-ignore
            this.route.navigate(['/']);
        }
        if (e.status === 400) {
            let str = '';
            e.error.errors.map((x) => {
                if (x.message.length > 0) {
                    str += x.message;
                }
            });
            alert(str);
        }
        // console.log(networkError);
    }
});

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
    const options: DefaultOptions = {
        watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'none',
        },
        query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'none',
        },
        mutate: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'none',
        }

    };

    return {
        link: error.concat(httpLink.create({uri})), // httpLink.create({uri}),
        cache: new InMemoryCache(),
        defaultOptions: options,

    };
}

@NgModule({
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [HttpLink],
        },
    ],
})
export class GraphQLModule {
}
