import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-definer',
  templateUrl: './definer.component.html',
  styleUrls: ['./definer.component.scss']
})
export class DefinerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
