import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-present-ideas',
  templateUrl: './present-ideas.component.html',
  styleUrls: ['./present-ideas.component.scss']
})
export class PresentIdeasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
