<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg">CURSOS</p>
        <div class="container">
            <h2 class="page_header_title">CURSOS</h2>
        </div>
    </div>
</div>
<div class="container" style="max-width: 900px">
    <div class="row my-5">


        <!-- NOT MEMBER -->
        <ng-container *ngIf="!flagMember">
            <app-not-member class="col-12"></app-not-member>
        </ng-container>

        <ng-container  *ngIf="flagMember">

            <div class="col-lg-12">
                <div class="row downloads_selector">
                    <div class="col-3 col-lg-12 info" *ngFor="let c of downloads" (click)="selectCategory(c.id)"
                         [ngClass]="{'active': section == c.id}">
                        <div class="icon">
                            <svg-icon src="assets/svg/{{handlerSvg(c.name.toLowerCase())}}.svg"></svg-icon>
                        </div>
                        <div class="title">{{c.name}}</div>
                    </div>
                </div>
            </div>

            <!-- MEMBER -->
            <div class="col-lg-8 offset-lg-1">
                <div class="downloads_content" *ngFor="let d of downloads |
                paginate: {itemsPerPage: categoryDownload.pageSize,
                currentPage: categoryDownload.currentPage,
                totalItems: categoryDownload.totalCount }">
                    <p>{{d.name}}</p>
                    <ng-container *ngIf="!this.isNullOrUndefined(d.path)">
                        <a href="javascript:void('');" (click)="downloadPath(d.path, d.id)">
                            <svg-icon src="assets/svg/download.svg"></svg-icon>
                        </a>
                    </ng-container>
                </div>
                <div class="d-flex h-100 justify-content-center align-items-center"
                     *ngIf="this.isNullOrUndefined(downloads)">
                    <p class="text-center my-4">
                        Nenhum Download encotrado
                    </p>
                </div>
                <div class="text-right">
                    <pagination-controls
                            [previousLabel]="labels.previousLabel"
                            [nextLabel]="labels.nextLabel"
                            [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                            [screenReaderPageLabel]="labels.screenReaderPageLabel"
                            [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                            (pageChange)="pageChanged($event)"></pagination-controls>
                </div>
            </div>

        </ng-container>

    </div>
</div>
