<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg">PAGANTE</p>
        <div class="container">
            <h2 class="page_header_title">SEJA PAGANTE</h2>
        </div>
    </div>
</div>
<div class="container" style="max-width: 900px">
    <div class="row my-5">


        <!-- NOT MEMBER -->
        <ng-container *ngIf="!flagMember">
            <app-not-member class="col-12"></app-not-member>
        </ng-container>

        <ng-container *ngIf="flagMember">

            <div class="d-flex justify-content-center w-100 py-5">
                <a href="javascript:void(0)" (click)="createOrder()" class="btn btn-primary">Pagamento</a>
            </div>

        </ng-container>

    </div>
</div>
