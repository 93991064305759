import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-maped',
  templateUrl: './maped.component.html',
  styleUrls: ['./maped.component.scss']
})
export class MapedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
