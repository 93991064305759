import { Component, OnInit } from '@angular/core';
declare var $ : any
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  handlerCollapse(id: string) {
    $('#' + id).collapse('toggle');
  }

}
