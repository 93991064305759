import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stitch',
  templateUrl: './stitch.component.html',
  styleUrls: ['./stitch.component.scss']
})
export class StitchComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
