import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jakeknapp',
  templateUrl: './jakeknapp.component.html',
  styleUrls: ['./jakeknapp.component.scss']
})
export class JakeknappComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
