import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sketch-solutions',
  templateUrl: './sketch-solutions.component.html',
  styleUrls: ['./sketch-solutions.component.scss']
})
export class SketchSolutionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
