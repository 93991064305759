import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conquest',
  templateUrl: './conquest.component.html',
  styleUrls: ['./conquest.component.scss']
})
export class ConquestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
