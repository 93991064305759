import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {NewByFriendlyUrlGQL, NewGQL, News, NewsGQL, NewsPage} from "../../../generated/graphql";


declare var $: any;

@Component({
    selector: 'app-content-detail',
    templateUrl: './news-detail.component.html',
    styleUrls: ['./news-detail.component.scss']
})
export class ContentDetailComponent extends BaseComponent implements OnInit {
    news: News = new News();
    newsPage: NewsPage = new NewsPage();

    constructor(public router: Router,
                public router2: ActivatedRoute,
                public newByFriendlyUrlGQL: NewByFriendlyUrlGQL,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngOnInit(): void {
        this.router2.params.subscribe(url => {
            if (!this.isNullOrUndefined(url)) {
                this.newByFriendlyUrlGQL.watch({url: url.id}).valueChanges.subscribe(({data}) => {
                    this.news = data.newByFriendlyUrl as News;
                }, error => super.onError(error));

            }
        });
    }
}
